import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useSessionExpire from '../../hooks/useSessionExpire';
import useUser from '../../hooks/useUser';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DealSBLC from './DealSBLC';
import dayjs from 'dayjs';
import axios from 'axios';

dayjs.extend(isSameOrBefore);

const ShipmentDetails = forwardRef(({ isWritable = false, isLoading = false, selectedTab = null, selectedPFI = null, setIsUpdated = () => {}, setActiveTab = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};
  const { isFoodsOrPolymersDeal, isFoodsPolymersOrSBLCDeal } = useMemo(() => 
  {
    const id = selectedBusinessUnit?.id;
    
    return {
      isFoodsOrPolymersDeal: [1, 4].includes(id),
      isFoodsPolymersOrSBLCDeal: [1, 4, 6].includes(id),
    };

  }, [selectedBusinessUnit]);

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();
  
  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const [products, setProducts] = useState([]);
  const [productGrades, setProductGrades] = useState([]); 
  const [seaPorts, setSeaPorts] = useState([]);
  const [shippingLines, setShippingLines] = useState([]);
  const partialShipments = useMemo(() => ["Allowed", "Not Allowed"], []);

  /* FILTER VARIABLES */
  const [tranches, setTranches] = useState([]);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const [toggleFilterData, setToggleFilterData] = useState(false);

  /* COPY VARIABLES */
  const [tranchesToCopy, setTranchesToCopy] = useState([]);
  const [selectedTrancheToCopy, setSelectedTrancheToCopy] = useState(null);
 
  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* FORM VARIABLES */
  const dataSchema = {
    Port_Of_Discharge_Id: null,
    Tranche_Name: "",
    Port_Of_Loading_Id: null,
    Shipping_Line: null,
    Vessel_Name: "",
    Voyage_No: "",
    Split_Bill_of_Lading_No: "",
    Master_Bill_of_Lading_No: "",
    Shipment_Date: null,
    Demurrage_Free_Days_at_Disport: null,
    Estimated_Arrival_to_POD: null,
    NN_Docs_Supplier: null,
    NN_Docs_Buyer: null,
    Documents_Status_to_Customer: "",
    Documents_Status_to_Supplier: "",
    Delivery_Date: null,
    Commercial_Invoice_No: "",
    Commercial_Invoice_Date: null,
    Gross_Weight_KGs: null,
    Net_Weight_KGs: null,
    Insurance_Cover_No: "",
    SI_Date: null,
    Shipment_Advice_Days: "",
    Insurance_Company_Details: "",
    Shipper: "",
    Notify_Bank: "",
    Notify_Customer: "",
    Consignee: "",
    Final_Destination: "",
    Partial_Shipment: "",
    Transhipment: "",
    Certificates_of_Origin: [],
    tranche_products_selected: [],
    tranche_product_grades_selected: []
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingShipmentDetails, setIsExistingShipmentDetails] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* SBLC VARIABLES */
  const sblcDataSchema = {
    SBLC_Id: null,
    PFIs: [],
    PFI_Id: []
  };
  const [initialSBLCFormData, setInitialSBLCFormData] = useState(sblcDataSchema);
  const [sblcFormData, setSBLCFormData] = useState(sblcDataSchema);
  const [isPFIAccordionExpanded, setIsPFIAccordionExpanded] = useState(null);
  const [isProductGradeAccordionExpanded, setIsProductGradeAccordionExpanded] = useState(null);
  const [isExistingSBLC, setIsExistingSBLC] = useState(false);
  
  /* ACCORDION VARIABLES */
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(null);
  const certificateAccordionRefs = useRef([]);
  const productOrProductGradeAccordionRefs = useRef([]);

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted && selectedTranche)
    {
      // Check if form data is different from the initial values
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      setIsUpdated(isFormUpdated);

      // Set accordian status
      certificateAccordionRefs.current = certificateAccordionRefs.current.slice(0, formData?.Certificates_of_Origin?.length);
      productOrProductGradeAccordionRefs.current = productOrProductGradeAccordionRefs.current.slice(0, isFoodsPolymersOrSBLCDeal ? formData?.tranche_product_grades_selected?.length : formData?.tranche_products_selected?.length);
    }
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted && selectedTranche)
    {
      // Check if SBLC form data is different from the initial values
      const isSBLCFormUpdated = Object.keys(initialSBLCFormData).some((key) => 
      {
        const sblcFormDataValue = sblcFormData[key];
        const initialSBLCFormDataValue = initialSBLCFormData[key];

        return (JSON.stringify(sblcFormDataValue) !== JSON.stringify(initialSBLCFormDataValue));
      });

      setIsUpdated(isSBLCFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sblcFormData]);
  
  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "post",
        url: "/GetShippingLineList",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const shippingLines = data?.shipping_lines || [];
          setShippingLines(shippingLines);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipping Lines Api: ", error);
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
      
      if (isFoodsOrPolymersDeal)
      {
        axios({
          method: "post",
          url: "/GetDealProductGrades",
          data: {
            Business_Unit_Id: selectedBusinessUnit?.id,
            Deal_PFI_Id: selectedPFI
          }
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            const dealProductGrades = data?.deal_product_grades || [];
            setProductGrades(dealProductGrades);
          }
        })
        .catch((error) => 
        {
          console.log("Get Deal Product Grades Api: ", error);
          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
        });
      }
      else if (!isFoodsPolymersOrSBLCDeal)
      {
        axios({
          method: "post",
          url: "/GetDealProducts",
          data: {
            Business_Unit_Id: selectedBusinessUnit?.id,
            Deal_PFI_Id: selectedPFI
          }
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            const dealProducts = data?.deal_products || [];
            setProducts(dealProducts);
          }
        })
        .catch((error) => 
        {
          console.log("Get Deal Products Api: ", error);
          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
        });
      }

      axios({
        method: "post",
        url: "/GetSeaPortList",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const seaports = data?.sea_ports || [];
          setSeaPorts(seaports);
        }
      })
      .catch((error) => 
      {
        console.log("Get Sea Ports Api: ", error);
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchLists();
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isFoodsPolymersOrSBLCDeal, isFoodsOrPolymersDeal]);
  
  useEffect(() => 
  {
    const fetchFilters = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetShipmentDetailFilters",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI 
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          if (isNewForm) 
          {
            setIsNewForm(false);
          }

          const tranches = data?.tranches || [];
          
          if (tranches.length > 0) 
          {
            if (formData?.Tranche_Name) 
            {
              const latestTranche = tranches.find((tranche) => tranche.value === formData?.Tranche_Name);
              setSelectedTranche(latestTranche);
            } 
            else 
            {
              setSelectedTranche(tranches[0]);
            }
          }

          setTranches(tranches);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Detail Filters Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedPFI) 
    {
      fetchFilters();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, toggleFilterData]);
  
  useEffect(() => 
  {    
    const fetchShipmentDetailsData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      const apiUrl = isFoodsPolymersOrSBLCDeal ? "/GetShipmentDetailsFoodsPolymersAndSBLC" : "/GetShipmentDetailsFertilizersAndPetroChemicals";

      await axios({
        method: "post",
        url: apiUrl,
        data: {
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTrancheToCopy?.id || selectedTranche?.id || ""
        }
      })
      .then((response) => 
      {
        if (selectedBusinessUnit?.id !== 6)
        {
          setIsLoading(false);
        }

        const { status, data } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? selectedTranche ? false : true : true;

          if (!isEmpty) 
          {
            setIsExistingShipmentDetails(true);
          } 
          else 
          {
            setIsNewForm(true);
          }

          let updatedData = { ...data?.shipment_details };
          const isAccordionExpanded = (isFoodsPolymersOrSBLCDeal ? updatedData?.tranche_product_grades_selected?.length : updatedData?.tranche_products_selected?.length) - 1;

          updatedData["Shipment_Date"] = updatedData["Shipment_Date"] ? dayjs(updatedData["Shipment_Date"]).format("MM/DD/YYYY") : updatedData["Shipment_Date"];
          updatedData["Estimated_Arrival_to_POD"] = updatedData["Estimated_Arrival_to_POD"] ? dayjs(updatedData["Estimated_Arrival_to_POD"]).format("MM/DD/YYYY") : updatedData["Estimated_Arrival_to_POD"];
          updatedData["NN_Docs_Supplier"] = updatedData["NN_Docs_Supplier"] ? dayjs(updatedData["NN_Docs_Supplier"]).format("MM/DD/YYYY") : updatedData["NN_Docs_Supplier"];
          updatedData["NN_Docs_Buyer"] = updatedData["NN_Docs_Buyer"] ? dayjs(updatedData["NN_Docs_Buyer"]).format("MM/DD/YYYY") : updatedData["NN_Docs_Buyer"];
          updatedData["Delivery_Date"] = updatedData["Delivery_Date"] ? dayjs(updatedData["Delivery_Date"]).format("MM/DD/YYYY") : updatedData["Delivery_Date"];
          updatedData["Commercial_Invoice_Date"] = updatedData["Commercial_Invoice_Date"] ? dayjs(updatedData["Commercial_Invoice_Date"]).format("MM/DD/YYYY") : updatedData["Commercial_Invoice_Date"];
          updatedData["SI_Date"] = updatedData["SI_Date"] ? dayjs(updatedData["SI_Date"]).format("MM/DD/YYYY") : updatedData["SI_Date"];

          if (selectedTranche) {
            updatedData["Tranche_Name"] =  selectedTranche?.value;
            updatedData["Tranche_Id"] = selectedTranche?.id;
          }

          setInitialFormData(JSON.parse(JSON.stringify(updatedData)));
          setFormData(updatedData);
          setIsAccordionExpanded(isAccordionExpanded);

          if (selectedBusinessUnit?.id === 6)
          {
            fetchSBLCData();
          }
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Details Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    const fetchSBLCData = async () =>
    {
      await axios({
        method: "post",
        url: "/GetDealTrancheSBLCProductGrades",
        data: { 
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTrancheToCopy?.id || selectedTranche?.id || null
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data = {} } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.Deal_PFI_Id ? false : true : true;

          if (!isEmpty && selectedTranche) 
          {
            setIsExistingShipmentDetails(true);            
            setIsExistingSBLC(true);
          }

          const pfiIndex = (data?.PFI_Id?.length ?? 0) - 1;
          const productGradeIndex = (data?.PFI_Id?.[pfiIndex]?.Product_Grade_Id?.length ?? 0) - 1;

          if (Object.keys(data).length > 0)
          {
            setInitialSBLCFormData(JSON.parse(JSON.stringify(data)));
            setSBLCFormData(data);
          }

          setIsPFIAccordionExpanded(pfiIndex);
          setIsProductGradeAccordionExpanded(productGradeIndex);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Details SBLC Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    // Assuming you have a list of all tranches, filter out the selected one
    const filteredTranches = tranches.filter(tranche => tranche.id !== selectedTranche?.id);
    setTranchesToCopy(filteredTranches);
    
    if (selectedPFI && (selectedTrancheToCopy || selectedTranche) && !isNewForm) 
    {
      fetchShipmentDetailsData();
    }
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, selectedTrancheToCopy, isNewForm]);

  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const handleUpload = async (Tranche_Id) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();
    const TrancheID = Tranche_Id || selectedTranche?.value;

    uploadedFiles.forEach((file, index) => 
    {
      const fileObj = {
        DealPFIId: selectedPFI,
        TrancheID: TrancheID || selectedTranche?.id,
        fileName: file.name,
        docType: `Shipment Details - ${TrancheID}`,
        fileExtension: file.name.split(".").pop(),
        tabType: file.tabType,
        fileType: file.docType,
        markForApproval: file.markForApproval
      };
    
      fileFormData.append(`file${index}`, JSON.stringify(fileObj));
      fileFormData.append(`file${index}`, file);
    });

    fileFormData.append("pathname", location?.pathname);

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setActiveTab("Documentation");
        setAlertMessage("Data saved successfully.");
        setAlertType("success");
        setShowAlert(true);

        setTimeout(() => 
        {
          if (isNewForm) 
          {
            setToggleFilterData(!toggleFilterData);
          }

          setUploadedFiles([]);
          setIsExistingShipmentDetails(true);
          setIsDataUpdated(false);
          setIsFormSaved(true);
        }, 2000);
      } 
      else if (status === 202)
      {
        setAlertMessage(data?.data || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);        
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {      
      console.log("Upload Shipment Details Documents Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleSelectTranche = (flag, newValue) =>
  {
    if (flag === "Copy")
    {
      setSelectedTrancheToCopy(newValue);
    }
    else
    {
      setSelectedTranche(newValue);
      setSelectedTrancheToCopy(null)
      setIsNewForm(false);
    }

    setFormData(dataSchema);
  }
  
  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (field === "Gross_Weight_KGs" || field === "Net_Weight_KGs") 
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 3 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
      }
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));

    if (isExistingShipmentDetails) 
    {
      setIsDataUpdated(true);
    }

    setIsFormSaved(false);
  }
 
  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
   
    let updatedFormData = {
      ...formData,
      Business_Unit_Id: selectedBusinessUnit?.id,
      Deal_PFI_Id: selectedPFI,
      Tranche_Name: formData?.Tranche_Name,
      User_Id: currentUser?.id,
      ...(isExistingShipmentDetails && { Tranche_ID: selectedTranche?.id })
    };

    const apiUrl = isExistingShipmentDetails
      ? isFoodsPolymersOrSBLCDeal
        ? "/UpdateShipmentDetailsFoodsPolymersAndSBLC"
        : "/UpdateShipmentDetailsFertilizersAndPetroChemicals" 
      : isFoodsPolymersOrSBLCDeal
        ? "/AddShipmentDetailsFoodsPolymersAndSBLC"
        : "/AddShipmentDetailsFertilizersAndPetroChemicals";
    let isSBLCSaved = selectedBusinessUnit?.id === 6 ? false : true;

    updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
      key,
      typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
    ]));

    if (selectedBusinessUnit?.id === 6)
    {
      let updatedSBLCFormData = {
        ...sblcFormData,
        Deal_PFI_Id: selectedPFI,
        Tranche_Name: formData?.Tranche_Name,
        User_Id: currentUser?.id,
        ...(isExistingShipmentDetails && { Tranche_ID: selectedTranche?.id })
      };
      const sblcApiUrl = isExistingSBLC ? "/UpdateDealTrancheSBLCProductGrades" : "/AddDealTrancheSBLCProductGrades";
  
      const transformObject = (obj, seen = new Set()) => 
      {
        if (seen.has(obj)) return obj; // Return the object if it has already been processed
        if (typeof obj !== "object" || obj === null) return obj; // Base case for non-objects
        
        seen.add(obj); // Add the current object to the seen set
        
        if (Array.isArray(obj)) 
        {
          return obj.map(item =>
            typeof item === "object" && item !== null
              ? item.hasOwnProperty("value")
                ? item.id
                : transformObject(item, seen) // Recursively transform nested arrays
              : item
          );
        }
      
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => [
            key,
            typeof value === "object" && value !== null
              ? Array.isArray(value)
                ? transformObject(value, seen)  // Handle arrays
                : value.hasOwnProperty("value")
                  ? value.id
                  : transformObject(value, seen)  // Recursively transform nested objects
              : value
          ])
        );
      }

      updatedSBLCFormData = transformObject(updatedSBLCFormData);

      await axios({
        method: "post",
        url: sblcApiUrl,
        data: updatedSBLCFormData
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          isSBLCSaved = true;
          updatedFormData.Tranche_Id = data?.tranche_id || null;

          if (!isExistingShipmentDetails) 
          {
            setToggleFilterData(!toggleFilterData);
          } 
          else 
          {
            setTranches((previousTranches) => 
            {
              let updatedTranches = previousTranches.map((tranche) => 
              {
                if (tranche.id === selectedTranche?.id && tranche.value !== formData?.Tranche_Name) 
                {
                  return { ...tranche, value: formData?.Tranche_Name };
                }

                return tranche;
              });

              // Set selected tranche value if updated
              if (selectedTranche?.id && formData?.Tranche_Name) 
              {
                const selectedTrancheUpdated = updatedTranches.find((tranche) => tranche.id === selectedTranche.id);

                if (selectedTrancheUpdated?.value !== selectedTranche.value) 
                {
                  setSelectedTranche({ ...selectedTranche, value: formData?.Tranche_Name });
                }
              }

              return updatedTranches;
            });
          }
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Shipment Details SBLC Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      }); 
    }

    if (isSBLCSaved)
    {
      await axios({
        method: "post",
        url: apiUrl,
        headers: { "Content-Type": "application/json" },
        data: updatedFormData
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          setIsFormSavedOrDeleted(true);
          setIsUpdated(false);

          if (uploadedFiles.length > 0) 
          {
            const Tranche_Id = response?.data?.Tranche_Id || selectedTranche?.id;

            if (Tranche_Id) 
            {
              handleUpload(Tranche_Id);
            }
          } 
          else 
          {
            setIsLoading(false);
            setAlertMessage("Data saved successfully.");
            setAlertType("success");
            setShowAlert(true);

            if (selectedTab) 
            {
              setActiveTab(selectedTab);
            } 
            else 
            {
              setActiveTab("Documentation");
            }
          }
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Shipment Details Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);
    let isSBLCDeleted = selectedBusinessUnit?.id === 6 ? false : true;

    if (selectedBusinessUnit?.id === 6)
    {
      await axios({
        method: "post",
        url: "/DeleteDealTrancheSBLCProductGrades",
        data: { 
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTrancheToCopy?.id || selectedTranche?.id,
          SBLC_Id: sblcFormData?.SBLC_Id?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          isSBLCDeleted = true;
          const resetSBLCFormData = {
            SBLC_Id: null,
            PFIs: [],
            PFI_Id: []
          }

          setInitialSBLCFormData(resetSBLCFormData);
          setSBLCFormData(resetSBLCFormData);
          setIsPFIAccordionExpanded(null);
          setIsProductGradeAccordionExpanded(null);
          setIsExistingSBLC(false);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Shipment Details SBLC Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (isSBLCDeleted)
    {
      await axios({
        method: "post",
        url: "/DeleteShipmentDetails",
        data: {
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTranche?.id
        }
      })
      .then(async (response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          await axios({
            method: "post",
            url: "/DeleteDocument",
            data: { 
            pathname: location?.pathname,
            fileIds: fileIds
          }
          })
          .then((response) => 
          {
            setIsLoading(false);
            const { status } = response;

            if (status === 200) 
            {
              const updatedTranches = tranches.filter((tranche) => tranche?.id !== selectedTranche?.id) || [];
              formRef?.current?.reset();

              if (updatedTranches.length > 0) 
              {
                setSelectedTranche(updatedTranches[0]);
              } 
              else 
              {
                setSelectedTranche(null);
              }

              setTranches(updatedTranches);
              
              const resetFormData = {
                ...formData,
                Port_Of_Discharge_Id: null,
                Tranche_Name: "",
                Port_Of_Loading_Id: null,
                Shipping_Line: null,
                Vessel_Name: "",
                Voyage_No: "",
                Split_Bill_of_Lading_No: "",
                Master_Bill_of_Lading_No: "",
                Shipment_Date: null,
                Demurrage_Free_Days_at_Disport: null,
                Estimated_Arrival_to_POD: null,
                NN_Docs_Supplier: null,
                NN_Docs_Buyer: null,
                Documents_Status_to_Customer: "",
                Documents_Status_to_Supplier: "",
                Delivery_Date: null,
                Commercial_Invoice_No: "",
                Commercial_Invoice_Date: null,
                Gross_Weight_KGs: null,
                Net_Weight_KGs: null,
                Insurance_Cover_No: "",
                SI_Date: null,
                Shipment_Advice_Days: "",
                Insurance_Company_Details: "",
                Shipper: "",
                Notify_Bank: "",
                Notify_Customer: "",
                Consignee: "",
                Final_Destination: "",
                Partial_Shipment: "",
                Transhipment: "",
                Certificates_of_Origin: [],
                tranche_products_selected: [],
                tranche_product_grades_selected: []
              };
              setFormData(resetFormData);
              setFiles([]);
              setUploadedFiles([]);
              setIsExistingShipmentDetails(false);
              setIsFormSavedOrDeleted(true);
              setIsDataUpdated(false);
              setIsFormSaved(false);
              setIsUpdated(false);
            } 
            else 
            {
              setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
              setAlertType("error");
              setShowAlert(true);
            }
          })
          .catch((error) => 
          {
            console.log("Delete Shipment Details Documents Api: ", error);
            setIsLoading(false);

            const status = error?.response?.status;

            if (status === 403) 
            {
              triggerSessionExpire();
            } 
            else 
            {
              setAlertMessage(
                status === 401
                  ? "Unauthorized access. You do not have the required permissions to perform this action."
                  : status === 429
                  ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                  : "An error occurred while processing your request. Please try again later or contact the site administrator."
              );
              setAlertType("error");
              setShowAlert(true);
          
              if (status === 429) 
              {
                setTimeout(logOut, 3000);
              }
            }
          });
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage(data?.data);
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Shipment Details Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }
  }

  const handleAddTrancheWrapper = () => 
  {
    const isEmpty = Object.entries(formData)?.every(([key, value]) => value === "" || value === null || value === undefined) && uploadedFiles.length === 0;
    const isAnyFormFieldFilled = Object.values(formData).some((value) => 
    {
      if (Array.isArray(value)) 
      {
        return value.some((nestedObject) =>
          Object.values(nestedObject).some((nestedValue) =>
            Boolean(nestedValue)
          )
        );
      }

      return Boolean(value);
    });

    if (!isAnyFormFieldFilled) 
    {
      setShowWarningDialog(true);
    } 
    else if (!isEmpty && !isFormSaved && (!isExistingShipmentDetails || isDataUpdated)) 
    {
      setShowAddModal(true);
    } 
    else 
    {
      handleAddTranche();
    }
  }

  const handleAddTranche = () => 
  {
    setShowAddModal(false);

    formRef?.current?.reset();
    
    setSelectedTranche(null);
    setFiles([]);
    setUploadedFiles([]);
    
    const resetFormData = {
      ...formData,
      Port_Of_Discharge_Id: null,
      Tranche_Name: "",
      Port_Of_Loading_Id: null,
      Shipping_Line: null,
      Vessel_Name: "",
      Voyage_No: "",
      Split_Bill_of_Lading_No: "",
      Master_Bill_of_Lading_No: "",
      Shipment_Date: null,
      Demurrage_Free_Days_at_Disport: null,
      Estimated_Arrival_to_POD: null,
      NN_Docs_Supplier: null,
      NN_Docs_Buyer: null,
      Documents_Status_to_Customer: "",
      Documents_Status_to_Supplier: "",
      Delivery_Date: null,
      Commercial_Invoice_No: "",
      Commercial_Invoice_Date: null,
      Gross_Weight_KGs: null,
      Net_Weight_KGs: null,
      Insurance_Cover_No: "",
      SI_Date: null,
      Shipment_Advice_Days: "",
      Insurance_Company_Details: "",
      Shipper: "",
      Notify_Bank: "",
      Notify_Customer: "",
      Consignee: "",
      Final_Destination: "",
      Partial_Shipment: "",
      Transhipment: "",
      Certificates_of_Origin: [],
      tranche_products_selected: [],
      tranche_product_grades_selected: []
    };
    const resetSBLCFormData = {
      SBLC_Id: null,
      PFIs: [],
      PFI_Id: []
    };

    setInitialFormData(resetFormData);
    setFormData(resetFormData);
    setIsExistingShipmentDetails(false);
    setIsDataUpdated(false);
    setIsNewForm(true);
    setInitialSBLCFormData(resetSBLCFormData);
    setSBLCFormData(resetSBLCFormData);
    setIsPFIAccordionExpanded(null);
    setIsProductGradeAccordionExpanded(null);
    setIsExistingSBLC(false);
    setIsUpdated(false);
  }

  const handleAccordionChange = (index) => (event, isExpanded, flag) =>
  {
    setIsAccordionExpanded(isExpanded ? index : isAccordionExpanded);

    if (flag === "Certificates") 
    {
      if (isExpanded && certificateAccordionRefs.current[index]) 
      {
        // Adjust timeout as needed for smooth scrolling
        setTimeout(() => 
        {
          if (certificateAccordionRefs.current[index]?.scrollIntoView) 
          {
            certificateAccordionRefs.current[index].scrollIntoView({behavior: "smooth"});
          }
        }, 300);
      }
    }
  }

  const handleCertificateChange = (index, field, value) => 
  {
    const newCertificates = [...formData?.Certificates_of_Origin];

    newCertificates[index][field] = value;
    setFormData((previousFormData) => ({
      ...previousFormData,
      Certificates_of_Origin: newCertificates
    }));
  }

  const handleAddCertificate = () => 
  {
    const certificates = formData?.Certificates_of_Origin || [];
    const isAnyCertificateFieldFilled =
      certificates?.length > 0
        ? certificates.every((certificate) =>
            Object.values(certificate).some((value) => Boolean(value))
          )
        : true;

    if (isAnyCertificateFieldFilled) 
    {
      const newCertificateData = {
        COO_Title: "",
        COO_Content: ""
      };

      setFormData((previousFormData) => ({
        ...previousFormData,
        Certificates_of_Origin: [
          ...(previousFormData?.Certificates_of_Origin || []),
          newCertificateData
        ]
      }));
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleAddNewCertificate = () => 
  {
    handleAddCertificate();

    if (formData.Certificates_of_Origin?.length > 0) 
    {
      // Expand the newly added accordion
      setIsAccordionExpanded(formData?.Certificates_of_Origin?.length);
    }
  }

  const handleDeleteCertificate = (event, index) => 
  {
    event.stopPropagation();
    
    const newCertificates = [...formData?.Certificates_of_Origin];

    if (isAccordionExpanded === index) 
    {
      setIsAccordionExpanded(0);
    }

    newCertificates.splice(index, 1);
    setFormData((previousFormData) => ({
      ...previousFormData,
      Certificates_of_Origin: newCertificates
    }));
  }

  const handleProductChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (field === "Shipped_Quantity") 
    {
      if (formattedValue) 
      {
        // Remove any non-numeric characters except the decimal point
        formattedValue = value.replace(/[^0-9.]/g, "");

        // Ensure the value has at most 3 decimal places
        if (formattedValue.includes(".")) 
        {
          const [integerPart, decimalPart] = formattedValue.split(".");
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        }
      } 
      else 
      {
        formattedValue = null;
      }
    }

    const newProducts = [...formData.tranche_products_selected];

    newProducts[index][field] = formattedValue;
    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_products_selected: newProducts
    }));
  }

  const handleAddProduct = () => 
  {
    const tranche_products_selected = [...formData?.tranche_products_selected];

    const isAnyProductFieldFilled =
      tranche_products_selected.length > 0
        ? tranche_products_selected.every((product) =>
            Object.values(product)?.some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductFieldFilled) 
    {
      const newProductData = {
        Deal_Product_Id: null,
        Shipped_Quantity: null
      };

      setFormData((previousFormData) => ({
        ...previousFormData,
        tranche_products_selected: [
          ...(previousFormData.tranche_products_selected || []),
          newProductData
        ]
      }));
      setIsAccordionExpanded(formData?.tranche_products_selected?.length);
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleAddNewProduct = () => 
  {
    handleAddProduct();

    if (formData.tranche_products_selected?.length > 0) 
    {
      // Expand the newly added accordion
      setIsAccordionExpanded(formData.tranche_products_selected?.length);
    }
  }
  
  const handleDeleteProduct = (event, index) => 
  {
    event.stopPropagation();

    if (isAccordionExpanded === index) 
    {
      setIsAccordionExpanded(0);
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_products_selected: previousFormData.tranche_products_selected.filter((_, i) => i !== index)
    }));
  }

  const handleProductGradeChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (field === "Shipped_Quantity") 
    {
      if (formattedValue) 
      {
        // Remove any non-numeric characters except the decimal point
        formattedValue = value.replace(/[^0-9.]/g, "");

        // Ensure the value has at most 3 decimal places
        if (formattedValue.includes(".")) 
        {
          const [integerPart, decimalPart] = formattedValue.split(".");
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        }
      } 
      else 
      {
        formattedValue = null;
      }
    }

    const newProductGrades = [...formData.tranche_product_grades_selected];

    newProductGrades[index][field] = formattedValue;
    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_product_grades_selected: newProductGrades
    }));
  }

  const handleAddProductGrade = () => 
  {
    const tranche_product_grades_selected = [...formData?.tranche_product_grades_selected] || [];

    const isAnyProductGradeFieldFilled =
      tranche_product_grades_selected.length > 0
        ? tranche_product_grades_selected.every((product) =>
            Object.values(product)?.some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductGradeFieldFilled) 
    {
      const newProductGradeData = {
        Deal_Product_Grade_Id: null,
        Shipped_Quantity: null
      };

      setFormData((previousFormData) => ({
        ...previousFormData,
        tranche_product_grades_selected: [
          ...(previousFormData.tranche_product_grades_selected || []),
          newProductGradeData
        ]
      }));
      setIsAccordionExpanded(formData?.tranche_product_grades_selected?.length);
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleAddNewProductGrade = () => 
  {
    handleAddProductGrade();

    if (formData.tranche_product_grades_selected?.length > 0) 
    {
      // Expand the newly added accordion
      setIsAccordionExpanded(formData.tranche_product_grades_selected?.length);
    }
  }
  
  const handleDeleteProductGrade = (event, index) => 
  {
    event.stopPropagation();

    if (isAccordionExpanded === index) 
    {
      setIsAccordionExpanded(0);
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_product_grades_selected: previousFormData.tranche_product_grades_selected.filter((_, i) => i !== index)
    }));
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
    setShowAddModal(false);
  }

  return (
    <div>
      <div className = "d-flex justify-content-between w-100 align-items-center mb-3">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Shipping Details Information
        </Typography>

        <div className = "multi-form mt-0 autocomplete">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            isWritable = {isWritable}
            filterOn = "Select Tranche to Auto Populate"
            options = {tranchesToCopy}
            selectedOptions = {selectedTrancheToCopy}
            handleSelectChange = {(filterOn, newValue) => handleSelectTranche("Copy", newValue)}
          />

          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            isWritable = {true}
            filterOn = "Select Tranche Name"
            options = {tranches}
            selectedOptions = {selectedTranche || null}
            handleSelectChange = {(filterOn, newValue) => handleSelectTranche("Select", newValue)}
          />

          <IconButton
            className = "add_button"
            color = "primary"
            aria-label = "add"
            disabled = {!isWritable}
            onClick = {handleAddTrancheWrapper}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      
      <div className = "input-fields p-2">
        <form
          ref = {formRef}
          className = "progress_form_con multi-form-container"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid size = {{ xs: 4 }} className = "form-text-field"></Grid>

              <Grid size = {{ xs: 4 }} className = "mt-4">
                <TextField
                  label = "Tranche Name"
                  id = "Tranche_Name"
                  value = {formData?.Tranche_Name || ""}
                  required
                  onChange = {(event) => handleChange("Tranche_Name", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>
              
              <Grid size = {{ xs: 4 }} className = "form-text-field"></Grid>
              
              {selectedBusinessUnit?.id === 6 ? (
                <>
                  <Grid size = {{ xs: 12 }}>
                    <hr />
                    
                    <DealSBLC 
                      isWritable = {isWritable}
                      selectedTab = "Shipment Details"
                      selectedPFI = {selectedPFI}
                      sblcFormData = {sblcFormData}
                      isPFIAccordionExpanded = {isPFIAccordionExpanded}
                      isProductGradeAccordionExpanded = {isProductGradeAccordionExpanded}
                      setShowAlert = {setShowAlert}
                      setAlertMessage = {setAlertMessage}
                      setAlertType = {setAlertType}
                      setSBLCFormData = {setSBLCFormData}
                      setIsPFIAccordionExpanded = {setIsPFIAccordionExpanded}
                      setIsProductGradeAccordionExpanded = {setIsProductGradeAccordionExpanded}
                    />
                  </Grid>
                </>
              ) : (
                <Grid size = {{ xs: 12 }}>
                  <hr />

                  <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                    <h3 className = "mb-0">Product{isFoodsOrPolymersDeal ? " Grade" : ""}s</h3>

                    <IconButton
                      className = "add_button"
                      color = "primary"
                      disabled = {!isWritable}
                      onClick = {isFoodsOrPolymersDeal ? handleAddNewProductGrade : handleAddNewProduct}
                    >
                      <AddIcon />
                    </IconButton>
                  </div>

                  {isFoodsOrPolymersDeal ? (
                    <>
                      {formData?.tranche_product_grades_selected?.length === 0 && (
                        <span>Select a product grade to ship.</span>
                      )}

                      {formData?.tranche_product_grades_selected?.map((productGrade, index) => (
                        <Accordion
                          key = {index}
                          expanded = {isAccordionExpanded === index}
                          ref = {(element) => (productOrProductGradeAccordionRefs.current[index] = element)}
                          onChange = {handleAccordionChange(index, "Tranche Product Grades")}
                        >
                          <AccordionSummary
                            expandIcon = {<ExpandMoreIcon />}
                            aria-controls = {`product-grade-content-${index}`}
                            id = {`product-grade-header-${index}`}
                          >
                            <Typography>Product Grade {index + 1}</Typography>
                            <IconButton
                              color = "error"
                              size = "medium"
                              disabled = {!isWritable}
                              onClick = {(event) => handleDeleteProductGrade(event, index)}
                            >
                              <DeleteIcon fontSize = "medium" />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing = {2}
                              className = "align-items-start autocomplete"
                            >
                              <Grid size = {{ xs: 3 }}>
                                <VirtualizedAutocomplete
                                  isMultiple = {false}
                                  isObject = {true}
                                  isRequired = {true}
                                  isWritable = {isWritable}
                                  filterOn = "Product Grade"
                                  options = {productGrades}
                                  selectedOptions = {productGrade.Deal_Product_Grade_Id || null}
                                  handleSelectChange = {(filterOn, newValue) => handleProductGradeChange(index, "Deal_Product_Grade_Id", newValue)}
                                />
                              </Grid>

                              <Grid size = {{ xs: 3 }} className = "form-text-field">
                                <TextField
                                  label = "Shipped Quantity"
                                  id = "Shipped_Quantity"
                                  type = "number"
                                  value = {productGrade.Shipped_Quantity || ""}
                                  onChange = {(event) => handleProductGradeChange(index, "Shipped_Quantity", event.target.value)}
                                  slotProps = {{ 
                                    input: { 
                                      readOnly: !isWritable,
                                      min: 0, 
                                      max: 2147483647, 
                                      step: "0.001"
                                    } 
                                  }}
                                  onKeyDown = {(event) => 
                                  {
                                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                    {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <>
                      {formData?.tranche_products_selected?.length === 0 && (
                        <span>Select a product to ship.</span>
                      )}

                      {formData?.tranche_products_selected?.map((product, index) => (
                        <Accordion
                          key = {index}
                          expanded = {isAccordionExpanded === index}
                          ref = {(element) => (productOrProductGradeAccordionRefs.current[index] = element)}
                          onChange = {handleAccordionChange(index, "Tranche Products")}
                        >
                          <AccordionSummary
                            expandIcon = {<ExpandMoreIcon />}
                            aria-controls = {`product-content-${index}`}
                            id = {`product-header-${index}`}
                          >
                            <Typography>Product {index + 1}</Typography>
                            <IconButton
                              color = "error"
                              size = "medium"
                              disabled = {!isWritable}
                              onClick = {(event) => handleDeleteProduct(event, index)}
                            >
                              <DeleteIcon fontSize = "medium" />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing = {2}
                              className = "align-items-start autocomplete"
                            >
                              <Grid size = {{ xs: 3 }}>
                                <VirtualizedAutocomplete
                                  isMultiple = {false}
                                  isObject = {true}
                                  isRequired = {true}
                                  isWritable = {isWritable}
                                  filterOn = "Product"
                                  options = {products}
                                  selectedOptions = {product.Deal_Product_Id || null}
                                  handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Deal_Product_Id", newValue)}
                                />
                              </Grid>

                              <Grid size = {{ xs: 3 }} className = "form-text-field">
                                <TextField
                                  label = "Shipped Quantity"
                                  id = "Shipped_Quantity"
                                  type = "number"
                                  value = {product.Shipped_Quantity || ""}
                                  onChange = {(event) => handleProductChange(index, "Shipped_Quantity", event.target.value)}
                                  slotProps = {{ 
                                    input: { 
                                      readOnly: !isWritable,
                                      min: 0, 
                                      max: 2147483647, 
                                      step: "0.001" 
                                    } 
                                  }}
                                  onKeyDown = {(event) => 
                                  {
                                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                    {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  )}
                </Grid>
              )}
              
              <Grid size = {{ xs: 12 }}>
                <hr />
                <div className = "w-100 d-flex justify-content-between align-items-center mt-1 mb-2">
                  <h3 className = "mb-0">Basic Information</h3>
                </div>
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Port of Loading"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_Of_Loading_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_Of_Loading_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Port of Discharge"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_Of_Discharge_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_Of_Discharge_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Shipping Line"
                  options = {shippingLines}
                  selectedOptions = {formData?.Shipping_Line || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Shipping_Line", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Vessel Name"
                  id = "Vessel_Name"
                  value = {formData?.Vessel_Name || ""}
                  onChange = {(event) => handleChange("Vessel_Name", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Voyage No."
                  id = "Voyage_No"
                  value = {formData?.Voyage_No || ""}
                  onChange = {(event) => handleChange("Voyage_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Master BL Number."
                  id = "Master_Bill_of_Lading_No"
                  value = {formData?.Master_Bill_of_Lading_No || ""}
                  onChange = {(event) => handleChange("Master_Bill_of_Lading_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Split BL Number."
                  id = "Split_Bill_of_Lading_No"
                  value = {formData?.Split_Bill_of_Lading_No || ""}
                  onChange = {(event) => handleChange("Split_Bill_of_Lading_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Shipment Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Shipment_Date)}
                    onChange = {(newDate) => handleChange("Shipment_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Shipment_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Demurrage Free Days at Disport"
                  type = "number"
                  id = "Demurrage_Free_Days_at_Disport"
                  value = {formData?.Demurrage_Free_Days_at_Disport || ""}
                  onChange = {(event) => handleChange("Demurrage_Free_Days_at_Disport", event.target.value)}
                  slotProps = {{ 
                    input: { 
                      readOnly: !isWritable,
                      min: 0, 
                      max: 2147483647 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Estimated Arrival to POD"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Estimated_Arrival_to_POD)}
                    onChange = {(newDate) => handleChange("Estimated_Arrival_to_POD", newDate, true)}
                    onAccept = {(newDate) => handleChange("Estimated_Arrival_to_POD", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Gross Weight KGs"
                  type = "number"
                  id = "Gross_Weight_KGs"
                  value = {formData?.Gross_Weight_KGs || ""}
                  onChange = {(event) => handleChange("Gross_Weight_KGs", event.target.value)}
                  slotProps = {{ 
                    input: { 
                      readOnly: !isWritable,
                      min: 0, max: 2147483647, 
                      step: "0.001" 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Net Weight KGs"
                  type = "number"
                  id = "Net_Weight_KGs"
                  value = {formData?.Net_Weight_KGs || ""}
                  onChange = {(event) => handleChange("Net_Weight_KGs", event.target.value)}
                  slotProps = {{ 
                    input: { 
                      readOnly: !isWritable,
                      min: 0, max: 2147483647, 
                      step: "0.001" 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Commercial Invoice No."
                  id = "Commercial_Invoice_No"
                  disabled
                  value = {formData?.Commercial_Invoice_No || ""}
                  onChange = {(event) => handleChange("Commercial_Invoice_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Document Creation Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Commercial_Invoice_Date)}
                    onChange = {(newDate) => handleChange("Commercial_Invoice_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Commercial_Invoice_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "NN Docs Supplier"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.NN_Docs_Supplier)}
                    onChange = {(newDate) => handleChange("NN_Docs_Supplier", newDate, true)}
                    onAccept = {(newDate) => handleChange("NN_Docs_Supplier", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "NN Docs Buyer"
                    className = "date-picker w-full"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    value = {convertToDayJsObject(formData?.NN_Docs_Buyer)}
                    onChange = {(newDate) => handleChange("NN_Docs_Buyer", newDate, true)}
                    onAccept = {(newDate) => handleChange("NN_Docs_Buyer", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "NN Documents Status - Customer"
                  id = "Documents_Status_to_Customer"
                  value = {formData?.Documents_Status_to_Customer || ""}
                  onChange = {(event) => handleChange("Documents_Status_to_Customer", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "NN Documents Status - Supplier"
                  id = "Documents_Status_to_Supplier"
                  value = {formData?.Documents_Status_to_Supplier || ""}
                  onChange = {(event) => handleChange("Documents_Status_to_Supplier", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Delivery Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Delivery_Date)}
                    onChange = {(newDate) => handleChange("Delivery_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Delivery_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Insurance Ref No."
                  id = "Insurance_Cover_No"
                  value = {formData?.Insurance_Cover_No || ""}
                  onChange = {(event) => handleChange("Insurance_Cover_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "SI Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.SI_Date)}
                    onChange = {(newDate) => handleChange("SI_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("SI_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Shipment Advice Days"
                  id = "Shipment_Advice_Days"
                  value = {formData?.Shipment_Advice_Days || ""}
                  onChange = {(event) => handleChange("Shipment_Advice_Days", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 4 }} className = "form-text-field">
                <TextField
                  label = "Insurance Company Details"
                  id = "Insurance_Company_Details"
                  multiline
                  rows = {3}
                  value = {formData?.Insurance_Company_Details || ""}
                  onChange = {(event) => handleChange("Insurance_Company_Details", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mt-3 mb-4">
                  <h3 className = "mb-0">Shipment Instructions</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete"
                >
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Shipper"
                      id = "Shipper"
                      value = {formData?.Shipper || ""}
                      onChange = {(event) => handleChange("Shipper", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Notify Bank"
                      id = "Notify_Bank"
                      value = {formData?.Notify_Bank || ""}
                      onChange = {(event) => handleChange("Notify_Bank", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Notify Customer"
                      id = "Notify_Customer"
                      value = {formData?.Notify_Customer || ""}
                      onChange = {(event) => handleChange("Notify_Customer", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Consignee"
                      id = "Consignee"
                      value = {formData?.Consignee || ""}
                      onChange = {(event) => handleChange("Consignee", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Final Destination"
                      id = "Final_Destination"
                      value = {formData?.Final_Destination || ""}
                      onChange = {(event) => handleChange("Final_Destination", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      isWritable = {isWritable}
                      filterOn = "Partial Shipment"
                      options = {partialShipments}
                      selectedOptions = {formData?.Partial_Shipment || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange("Partial_Shipment", newValue)}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      isWritable = {isWritable}
                      filterOn = "Transhipment"
                      options = {partialShipments}
                      selectedOptions = {formData?.Transhipment || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange("Transhipment", newValue)}
                    />
                  </Grid>
                </Grid>

                <hr />
              </Grid>

              <Grid size = {{ xs: 12 }} className = "mb-3">
                <div className = "w-100 d-flex justify-content-between align-items-center mb-2">
                  <h3 className = "mb-0">Other Certificates</h3>

                  <IconButton
                    className = "add_button"
                    color = "primary"
                    disabled = {!isWritable}
                    onClick = {handleAddNewCertificate}
                  >
                    <AddIcon />
                  </IconButton>
                </div>

                {formData?.Certificates_of_Origin?.map(
                  (certificate, index) => (
                    <Accordion
                      key = {index}
                      ref = {(element) => (certificateAccordionRefs.current[index] = element)}
                      expanded = {isAccordionExpanded === index}
                      onChange = {handleAccordionChange(index, "Certificates")}
                    >
                      <AccordionSummary
                        expandIcon = {<ExpandMoreIcon />}
                        aria-controls = {`product-content-${index}`}
                        id = {`product-header-${index}`}
                      >
                        <Typography>Other Certificate {index + 1}</Typography>
                        <IconButton
                          color = "error"
                          size = "medium"
                          disabled = {!isWritable}
                          onClick = {(event) => handleDeleteCertificate(event, index)}
                        >
                          <DeleteIcon fontSize = "medium" />
                        </IconButton>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className = "row w-100 flex-column align-items-start gap-2">
                          <div className = "col-3 form-text-field p-0">
                            <TextField
                              label = "Title"
                              id = "COO_Title"
                              value = {certificate.COO_Title || ""}
                              onChange = {(event) => handleCertificateChange(index, "COO_Title", event.target.value)}
                              slotProps = {{
                                input: {
                                  readOnly: !isWritable
                                }
                              }}
                            />
                          </div>

                          <div className = "col-6 form-text-field p-0">
                            <TextField
                              label = "Content"
                              id = "COO_Content"
                              multiline
                              rows = {3}
                              value = {certificate.COO_Content || ""}
                              onChange = {(event) => handleCertificateChange(index, "COO_Content", event.target.value)}
                              slotProps = {{
                                input: {
                                  readOnly: !isWritable
                                }
                              }}
                            />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end submit-buttons">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {!isWritable || isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingShipmentDetails ? "Update" : "Save"}
        </button>

        {isExistingShipmentDetails && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {!isWritable || isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the form before adding a new
            entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom_btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete this tranche? This action will remove
            it from the shipment details which cannot be undone.
          </span>
        </Modal.Body>

        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show = {showAddModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Adding a new form will discard your unsaved changes. Proceed
            anyway?
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleAddTranche}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default ShipmentDetails;