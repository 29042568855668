import { createContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../actions/user';
import { sendBroadcastMessage } from '../utility/broadcastChannel';
import useUser from '../hooks/useUser';
import Alert from '../components/Alert';
import axios from 'axios';

const LogoutModalContext = createContext();

const LogoutModalProvider = ({ children }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* MODAL VARIABLES */
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    const handleExtendSession = () => 
    {
        sendBroadcastMessage('logout-modal-sync', { type: 'EXTEND_SESSION' });
        setShowLogoutModal(false);
    }

    const handleLogout = async () => 
    {
        sendBroadcastMessage('logout-modal-sync', { type: 'LOGOUT' });
        
        if (!currentUser?.id) 
        {
            setShowLogoutModal(false);
            dispatch(setUser(null, null, null, null, false));
            localStorage.setItem('isLoggedIn', false);
            localStorage.setItem('isLoggedOut', true);
            navigate("/login");
            
            return;
        }

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/LogoutUser",
            data: { user_id: currentUser?.id }
        })
        .then((response) => 
        {
            setIsLoading(false);
            setShowLogoutModal(false);
            const { status } = response;

            if (status === 200) 
            {
                dispatch(setUser(null, null, null, null, false));
                localStorage.setItem('isLoggedIn', false);
                localStorage.setItem('isLoggedOut', true);
                navigate("/login");
            } 
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            setIsLoading(false);
            setShowLogoutModal(false);
            const status = error?.response?.status;

            if (status === 403) 
            {
                dispatch(setUser(null, null, null, null, false));
                localStorage.setItem('isLoggedIn', false);
                localStorage.setItem('isLoggedOut', true);
                navigate("/login");
            } 
            else 
            {
                setAlertMessage("An error occurred. Please try again later.");
                setAlertType("error");
                setShowAlert(true);
            }
        });
    }

    return (
        <LogoutModalContext.Provider
            value = {{
                isLoading,
                showLogoutModal,
                isSessionExpired,
                setIsLoading,
                setShowLogoutModal,
                setIsSessionExpired,
                handleExtendSession,
                handleLogout
            }}
        >
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />
            {children}
        </LogoutModalContext.Provider>
    );
}

export { LogoutModalContext, LogoutModalProvider };