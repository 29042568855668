import { useMemo } from 'react';

export const roles = {
    1: process.env.REACT_APP_ROLE_SECRET_KEY_1,
    2: process.env.REACT_APP_ROLE_SECRET_KEY_2,
    3: process.env.REACT_APP_ROLE_SECRET_KEY_3,
    4: process.env.REACT_APP_ROLE_SECRET_KEY_4,
    5: process.env.REACT_APP_ROLE_SECRET_KEY_5,
    6: process.env.REACT_APP_ROLE_SECRET_KEY_6,
    7: process.env.REACT_APP_ROLE_SECRET_KEY_7,
    8: process.env.REACT_APP_ROLE_SECRET_KEY_8,
    9: process.env.REACT_APP_ROLE_SECRET_KEY_9,
    10: process.env.REACT_APP_ROLE_SECRET_KEY_10,
    11: process.env.REACT_APP_ROLE_SECRET_KEY_11,
    12: process.env.REACT_APP_ROLE_SECRET_KEY_12,
    13: process.env.REACT_APP_ROLE_SECRET_KEY_13,
    14: process.env.REACT_APP_ROLE_SECRET_KEY_14,
    15: process.env.REACT_APP_ROLE_SECRET_KEY_15,
    16: process.env.REACT_APP_ROLE_SECRET_KEY_16,
    17: process.env.REACT_APP_ROLE_SECRET_KEY_17,
    18: process.env.REACT_APP_ROLE_SECRET_KEY_18,    
    19: process.env.REACT_APP_ROLE_SECRET_KEY_19
};

export const rolesMap = new Map([
    [process.env.REACT_APP_ROLE_SECRET_KEY_1, "Administrator"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_2, "Document Team"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_3, "LC Team"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_4, "Fertilizer Trade Partner"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_5, "Fertilizer Trade Partner Supervisor"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_6, "Fertilizer Trader"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_7, "GRC"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_8, "Finance"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_9, "Master Data Manager"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_10, "Foods and Agri Trade Partner"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_11, "Foods and Agri Trade Partner Supervisor"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_12, "Foods and Agri Trader"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_13, "Polymer Trade Partner"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_14, "Polymer Trade Partner Supervisor"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_15, "Polymer Trader"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_16, "Petrochemical Trade Partner"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_17, "Petrochemical Trade Partner Supervisor"],
    [process.env.REACT_APP_ROLE_SECRET_KEY_18, "Petrochemical Trader"],    
    [process.env.REACT_APP_ROLE_SECRET_KEY_19, "Executive"]    
]);

export const RoleLabel = ({ role }) => 
{
    const roleLabel = useMemo(() => 
    {
        return rolesMap.get(role);
    }, [role]);
  
    return roleLabel;
};