import { useContext } from 'react';
import { LogoutModalContext } from '../contexts/LogoutModalContext';

const useSessionExpire = () => 
{
    const { setIsSessionExpired = () => {}, setShowLogoutModal = () => {} } = useContext(LogoutModalContext);

    const triggerSessionExpire = () => 
    {
        setIsSessionExpired(true);
        setShowLogoutModal(true);
    }

    return triggerSessionExpire;
}

export default useSessionExpire;