import { useEffect, useRef } from 'react';
import { MdOutlineArrowBack } from 'react-icons/md';
import MenuList from '../components/MenuList';
import useOutsideAlerter from '../hooks/useOutsideAlerter';
import '../styles/components.scss';

const SidePanel = ({ menuConfig, allowedRoutes, pathname, toggleButtonRef, openSidePanel, isDealSubMenuOpen, isPolymerDealSubMenuOpen, isEntitySubMenuOpen, isReportSubMenuOpen, setOpenSidePanel, handleMenuClick, handleSubMenuClick }) => 
{
  const sidePanelRef = useRef(null);
  const isToggleButtonClicked = !useOutsideAlerter(toggleButtonRef);
  const isOutsideSidePanelClick = useOutsideAlerter(sidePanelRef);

  useEffect(() =>
  {
    if (!isToggleButtonClicked && isOutsideSidePanelClick)
    {
      setOpenSidePanel(false);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isOutsideSidePanelClick]);

  return (
    <div ref = {sidePanelRef} className = {`sidePanel ${openSidePanel ? 'openSidePanel' : ''}`}>
      <div className = "backButton">
        <a
          href = "#/"
          onClick = {(event) => 
          {
            event.preventDefault();
            setOpenSidePanel(false);
          }}
        >
          <MdOutlineArrowBack /> Back
        </a>
      </div>

      <div className = "d-flex flex-column justify-content-between" style = {{ height: '65%' }}>
        <div className = "nav_menu">
          <MenuList 
            menuConfig = {menuConfig}
            allowedRoutes = {allowedRoutes}
            pathname = {pathname}
            isDealSubMenuOpen = {isDealSubMenuOpen}
            isPolymerDealSubMenuOpen = {isPolymerDealSubMenuOpen}
            isEntitySubMenuOpen = {isEntitySubMenuOpen}
            isReportSubMenuOpen = {isReportSubMenuOpen}
            handleMenuClick = {handleMenuClick}
            handleSubMenuClick = {handleSubMenuClick}
          />
        </div>
      </div>
    </div>
  );
}

export default SidePanel;