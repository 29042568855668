import { useMemo, useState } from 'react';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';
import Currency from './Currency';
import Inconterm from './Incoterm';
import PackagingType from './PackagingType';
import PaymentTerm from './PaymentTerm';
import ProductGrade from './ProductGrade';
import SeaPort from './SeaPort';
import ShippingLine from './ShippingLine';
import UnitofMeasurement from './UnitofMeasurement';

const Dropdowns = ({ logOut = () => {} }) => 
{
  const dropdowns = useMemo(() => ["Currency", "Incoterm", "Packaging Type", "Payment Term", "Product Grade", "Sea Port", "Shipping Line", "Unit of Measurement"], []);
  const [selectedDropdown, setSelectedDropdown] = useState(null);

  return (
    <div className = "content_container d-flex flex-column container entities dropdown">
      <div className = "autocomplete">
        <VirtualizedAutocomplete
          isMultiple = {false}
          isObject = {false}
          filterOn = "Select Dropdown"
          options = {dropdowns}
          selectedOptions = {selectedDropdown}
          handleSelectChange = {(filterOn, newValue) => setSelectedDropdown(newValue)}
        />
      </div>

      {selectedDropdown === "Currency" ? (
        <Currency logOut = {logOut}/>
      ) : selectedDropdown === "Incoterm" ? (
        <Inconterm logOut = {logOut}/>
      ) : selectedDropdown === "Packaging Type" ? (
        <PackagingType logOut = {logOut}/>
      ) : selectedDropdown === "Payment Term" ? (
        <PaymentTerm logOut = {logOut}/>
      ) : selectedDropdown === "Product Grade" ? (
        <ProductGrade logOut = {logOut}/>
      ) : selectedDropdown === "Sea Port" ? (
        <SeaPort logOut = {logOut}/>
      ) : selectedDropdown === "Shipping Line" ? (
        <ShippingLine logOut = {logOut}/>
      ) : selectedDropdown === "Unit of Measurement" && (
        <UnitofMeasurement logOut = {logOut}/>
      )}
    </div>
  );
}

export default withAuth(Dropdowns)([roles.Administrator]);