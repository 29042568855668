import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSessionExpire from '../../../hooks/useSessionExpire';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';

const SBLCDetails = ({ selectedSBLC = {}, toggleDisplayData = false, setAlertMessage = () => {}, setAlertType = () => {}, setShowAlert = () => {}, setSelectedSBLC = () => {}, setToggleDisplayData = () => {}, logOut = () => {} }) => 
{
  const [isPFILoading, setIsPFILoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isCILoading, setIsCILoading] = useState(false);
  const [isProductGradeLoading, setIsProductGradeLoading] = useState(false);
  const { pathname } = useLocation();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* SELECTED SBLC VARIABLES */
  const [selectedPFI, setSelectedPFI] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCI, setSelectedCI] = useState(null);
  
  /* FILTER VARIABLES */
  const [pfis, setPFIList] = useState([]);
  const [product, setProduct] = useState(null);
  const [ciList, setCIList] = useState([]);
  const [productGradeList, setProductGradeList] = useState([]);

  /* SEARCH QUERY VARIABLES */
  const [pfiSearchQuery, setPFISearchQuery] = useState("");
  const [ciSearchQuery, setCISearchQuery] = useState("");
  const [productGradeSearchQuery, setProductGradeSearchQuery] = useState("");
  const lowerCasePFIQuery = pfiSearchQuery?.toLowerCase() || "";
  const lowerCaseCIQuery = ciSearchQuery?.toLowerCase() || "";
  const lowerCaseProductGradeQuery = productGradeSearchQuery?.toLowerCase() || "";

  /* SELECTED FILTER VARIABLES */
  const filteredPFIList = !lowerCasePFIQuery
  ? pfis
  : pfis?.filter(pfi => pfi["value"]?.toLowerCase().includes(lowerCasePFIQuery)) || [];
  const filteredCIList = !lowerCaseCIQuery
  ? ciList
  : ciList?.filter(ci => ci["value"]?.toLowerCase().includes(lowerCaseCIQuery)) || [];
  const filteredProductGradeList = !lowerCaseProductGradeQuery
  ? productGradeList
  : productGradeList?.filter(productGrade => productGrade["value"]?.toLowerCase().includes(lowerCaseProductGradeQuery)) || [];

  useEffect(() => 
  {
    const fetchPFIs = async () => 
    {
      setIsPFILoading(true);

      await axios({
        method: "post",
        url: "/GetSBLCPFIList",
        data: { 
          pathname: pathname,
          sblc_id: selectedSBLC?.id
        }
      })
      .then((response) => 
      {
        setIsPFILoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const pfiData = data?.pfis || [];
          setPFIList(pfiData);
        }
      })
      .catch((error) => 
      {
        console.log("Get PFI List Api: ", error);
        setIsPFILoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedSBLC) 
    {
      fetchPFIs();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedSBLC]);
  
  useEffect(() => 
  {
    const fetchProduct = async () => 
    {
      setIsProductLoading(true);

      await axios({
        method: "post",
        url: "/GetSBLCProduct",
        data: { pfi_id: selectedPFI?.id }
      })
      .then((response) => 
      {
        setIsProductLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const product = data?.product?.Product_Name || null;
          setProduct(product);
        }
      })
      .catch((error) => 
      {
        console.log("Get Product Api: ", error);
        setIsProductLoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedPFI) 
    {
      fetchProduct();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI]);
  
  useEffect(() => 
  {
    const fetchCIs = async () => 
    {
      setIsCILoading(true);

      await axios({
        method: "post",
        url: "/GetSBLCCommercialInvoiceList",
        data: { pfi_id: selectedPFI?.id }
      })
      .then((response) => 
      {
        setIsCILoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const ciData = data?.commercial_invoices || [];
          setCIList(ciData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Commercial Invoice List Api: ", error);
        setIsCILoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedProduct) 
    {
      fetchCIs();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedProduct]);
  
  useEffect(() => 
  {
    const fetchProductGrades = async () => 
    {
      setIsProductGradeLoading(true);
    
      await axios({
        method: "post",
        url: "/GetSBLCProductGradeList",
        data: { 
          pfi_id: selectedPFI?.id,
          commercial_invoice_id: selectedCI?.id 
        }
      })
      .then((response) => 
      {
        setIsProductGradeLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const productGradeData = data?.product_grades || [];
          setProductGradeList(productGradeData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Product Grade List Api: ", error);
        setIsProductGradeLoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedCI) 
    {
      fetchProductGrades();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedCI]);
 
  const handlePFIClick = (pfi) => 
  {
    if (selectedPFI !== pfi)
    {
      setSelectedPFI(pfi);
      setSelectedProduct(null);
      setSelectedCI(null);
      setProduct(null);
      setCIList([]);
      setProductGradeList([]);
    }
  }

  const handleProductClick = (product) => 
  {
    if (selectedProduct !== product)
    {
      setSelectedProduct(product);
      setCIList([]);
      setSelectedCI(null);
      setProductGradeList([]);
    }
  }

  const handleCIClick = (ci) => 
  {
    if (selectedCI !== ci)
    {
      setSelectedCI(ci);
      setProductGradeList([]);
    }
  }

  const handleBackPress = () =>
  {
    setSelectedSBLC(null);
    setToggleDisplayData(!toggleDisplayData);
  }
  
  return (
    <div className = "position-relative">
      <div className = "d-flex justify-content-start align-items-center gap-2 mb-2">
        <IconButton color = "primary" sx = {{ boxShadow: 3 }} onClick = {handleBackPress}>
          <ArrowBackIcon fontSize = "medium" />
        </IconButton>

        <h3 className = "m-0">{selectedSBLC?.Name}</h3>
      </div>
      
      <div className = "d-flex justify-content-center align-items-center gap-4 flex-wrap sblc-details">
        <div>
          <Typography variant = "h7" gutterBottom className = "page_heading mb-2">
            Proforma Invoices
          </Typography>

          <div className = "card m-0 p-2 entities">
            <div className = "sblc-input">
              <input
                className = "form-control"
                type = "text"
                placeholder = "Search PFI"
                value = {pfiSearchQuery}
                onChange = {(event) => setPFISearchQuery(event.target.value)}
              />
            </div>

            <Box sx = {{ width: 230, marginTop: '5px', bgcolor: 'background.paper', position: 'relative' }}>
              <Backdrop
                sx = {{ color: '#fff', borderRadius: 0, position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isPFILoading}
              >
                <CircularProgress color = "inherit" />
              </Backdrop>

              <List
                sx = {{
                  width: 230,
                  bgcolor: 'background.paper',
                  position: 'relative',
                  overflow: 'auto',
                  height: 'calc(100vh - 205px)',
                  padding: 0
                }}
              >
                {filteredPFIList?.length > 0 ? (
                  <Fragment>
                    {filteredPFIList?.map((pfi) => (
                      <ListItem key = {pfi.id} component = "div" dense disablePadding>
                        <ListItemButton selected = {selectedPFI?.id === pfi?.id} onClick = {() => handlePFIClick(pfi)}>
                          <ListItemText primary = {pfi.value} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </Fragment>
                ) : (
                  <span className = "d-flex justify-content-center align-items-center h-full">
                    No options
                  </span>
                )}
              </List>
            </Box>
          </div>
        </div>

        {selectedPFI && (
          <div>
            <Typography variant = "h7" gutterBottom className = "page_heading mb-2">
              Product
            </Typography>

            <div className = "card m-0 p-2 entities">
              <Box sx = {{ width: 230, marginTop: '5px', bgcolor: 'background.paper', position: 'relative' }}>
                <Backdrop
                  sx = {{ color: '#fff', borderRadius: 0, position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open = {isProductLoading}
                >
                  <CircularProgress color = "inherit" />
                </Backdrop>

                <List
                  sx = {{
                    width: 230,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 'calc(100vh - 172px)',
                    padding: 0
                  }}
                >
                  {product ? (
                    <Fragment>
                      <ListItem component = "div" dense disablePadding>
                        <ListItemButton selected = {selectedProduct === product} onClick = {() => handleProductClick(product)}>
                          <ListItemText primary = {product} />
                        </ListItemButton>
                      </ListItem>
                    </Fragment>
                  ) : (
                    <span className = "d-flex justify-content-center align-items-center h-full">
                      No option
                    </span>
                  )}
                </List>
              </Box>
            </div>
          </div>        
        )}

        {selectedProduct && (
          <div>
            <Typography variant = "h7" gutterBottom className = "page_heading mb-2">
              Commercial Invoices
            </Typography>

            <div className = "card m-0 p-2 entities">
              <div className = "sblc-input">
                <input
                  className = "form-control"
                  type = "text"
                  placeholder = "Search CI"
                  value = {ciSearchQuery}
                  onChange = {(event) => setCISearchQuery(event.target.value)}
                />
              </div>

              <Box sx = {{ width: 230, marginTop: '5px', bgcolor: 'background.paper', position: 'relative' }}>
                <Backdrop
                  sx = {{ color: '#fff', borderRadius: 0, position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open = {isCILoading}
                >
                  <CircularProgress color = "inherit" />
                </Backdrop>

                <List
                  sx = {{
                    width: 230,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 'calc(100vh - 205px)',
                    padding: 0
                  }}
                >
                  {filteredCIList?.length > 0 ? (
                    <Fragment>
                      {filteredCIList?.map((ci) => (
                        <ListItem key = {ci.id} component = "div" dense disablePadding>
                          <ListItemButton selected = {selectedCI?.id === ci?.id} onClick = {() => handleCIClick(ci)}>
                            <ListItemText primary = {ci.value} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Fragment>
                  ) : (
                    <span className = "d-flex justify-content-center align-items-center h-full">
                      No options
                    </span>
                  )}
                </List>
              </Box>
            </div>
          </div>        
        )}

        {selectedCI && (
          <div>
            <Typography variant = "h7" gutterBottom className = "page_heading mb-2">
              Product Grades
            </Typography>

            <div className = "card m-0 p-2 entities">
              <div className = "sblc-input">
                <input
                  className = "form-control"
                  type = "text"
                  placeholder = "Search Product Grade"
                  value = {productGradeSearchQuery}
                  onChange = {(event) => setProductGradeSearchQuery(event.target.value)}
                />
              </div>

              <Box sx = {{ width: 230, marginTop: '5px', bgcolor: 'background.paper', position: 'relative' }}>
                <Backdrop
                  sx = {{ color: '#fff', borderRadius: 0, position: 'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open = {isProductGradeLoading}
                >
                  <CircularProgress color = "inherit" />
                </Backdrop>

                <List
                  sx = {{
                    width: 230,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    height: 'calc(100vh - 205px)',
                    padding: 0
                  }}
                >
                  {filteredProductGradeList?.length > 0 ? (
                    <Fragment>
                      {filteredProductGradeList?.map((productGrade) => (
                        <ListItem key = {productGrade.id} component = "div" dense disablePadding>
                          <ListItemButton sx = {{ cursor: 'default' }}>
                            <ListItemText primary = {productGrade.value} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Fragment>
                  ) : (
                    <span className = "d-flex justify-content-center align-items-center h-full">
                      No options
                    </span>
                  )}
                </List>
              </Box>
            </div>
          </div>        
        )}
      </div>
    </div>
  );
}

export default SBLCDetails;