import { createContext, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useLogout from '../hooks/useLogout';
import ProtectedRoute from './ProtectedRoute';
import SidebarNavigation from '../navigation/SidebarNavigation';
import Login from '../pages/authentication/Login';
import SetNewPassword from '../pages/authentication/SetNewPassword';
import ResetPassword from '../pages/authentication/ResetPassword';
import ChangePassword from '../pages/authentication/ChangePassword';
import CreateUser from '../pages/authentication/CreateUser';
import EditProfile from '../pages/authentication/EditProfile';
import BusinessUnit from '../pages/deals/BusinessUnit';
import Trader from '../pages/entities/Trader';
import Tenor from '../pages/entities/Tenor';
import Bank from '../pages/entities/Bank';
import Deal from '../pages/entities/Deal';
import DealPFI from '../pages/entities/DealPFI';
import Dropdowns from '../pages/entities/Dropdowns';
import Product from '../pages/entities/Product';
import SBLC from '../pages/entities/SBLC/SBLC';
import Vendor from '../pages/entities/Vendor';
import PageNotFound from '../PageNotFound';
import Reports from '../pages/reports/Reports';
import ApproveDocuments from '../pages/documents/ApproveDocuments';

export const UserContext = createContext();

const RouterLinks = () => 
{
    /* CURRENT USER VARIABLES */
    const user = useSelector(state => state.user);
    const [currentUser, setCurrentUser] = useState(user.email ? user : null);

    /* SESSION VARIABLES */
    const sessionLogout = useLogout();

    const handleLogout = () => 
    {
        if (currentUser) 
        {
            setCurrentUser(null);
            sessionLogout();
        }
    }

    return (
        <UserContext.Provider value = {currentUser}>
            <Routes>
                <Route
                    path = "/login"
                    element = {
                        <Login setCurrentUser = {setCurrentUser}/>
                    }
                />

                <Route
                    path = "/set-new-password"
                    element = {<SetNewPassword currentUser = {currentUser} />}
                />

                <Route
                    path = "/reset-password"
                    element = {<ResetPassword currentUser = {currentUser} />}
                />

                <Route
                    path = "/change-password/user/:id"
                    element = {<ChangePassword currentUser = {currentUser} />}
                />

                <Route element = {<ProtectedRoute isAllowed = {!!currentUser} />}>
                    <Route
                        path = "/"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/fertilizers"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/foods"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/miscellaneous"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/petrochemicals/feedstocks"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/polymers"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/deals/sblc"
                        element = {
                            <SidebarNavigation
                                component = {<BusinessUnit logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/banks"
                        element = {
                            <SidebarNavigation
                                component = {<Bank logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/trader"
                        element = {
                            <SidebarNavigation
                                component = {<Trader logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/tenor"
                        element = {
                            <SidebarNavigation
                                component = {<Tenor logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/deals"
                        element = {
                            <SidebarNavigation
                                component = {<Deal logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/deal-pfis"
                        element = {
                            <SidebarNavigation
                                component = {<DealPFI logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/dropdowns"
                        element = {
                            <SidebarNavigation
                                component = {<Dropdowns logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/products"
                        element = {
                            <SidebarNavigation
                                component = {<Product logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/SBLC"
                        element = {
                            <SidebarNavigation
                                component = {<SBLC logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/entities/vendors"
                        element = {
                            <SidebarNavigation
                                component = {<Vendor logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/reports/deal-report"
                        element = {
                            <SidebarNavigation
                                component = {<Reports logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/reports/finance-report"
                        element = {
                            <SidebarNavigation
                                component = {<Reports logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/reports/morning-report"
                        element = {
                            <SidebarNavigation
                                component = {<Reports logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/create-user"
                        element = {
                            <SidebarNavigation
                                component = {<CreateUser logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/edit-profile"
                        element = {
                            <SidebarNavigation
                                component = {<EditProfile logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />

                    <Route
                        path = "/approve-documents"
                        element = {
                            <SidebarNavigation
                                component = {<ApproveDocuments logOut = {handleLogout} />}
                                logOut = {handleLogout}
                            />
                        }
                    />
                </Route>

                <Route
                    path = "*"
                    element = {currentUser ? <PageNotFound /> : <Navigate to = "/login" replace = {true} />}
                />
            </Routes>
        </UserContext.Provider>
    );
}

export default RouterLinks;