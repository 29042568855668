import { useState, useEffect } from 'react';
import { setUser } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { rolesMap } from '../../utility/roles';
import routeControl from '../../lib/routeControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Logo from '../../images/logo.png';
import axios from 'axios';
import '../../styles/authentication.css';

const Login = () => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* AUTHORIZATION VARIABLES */
  const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem("isLoggedOut"));

  /* PASSWORD VARIABLES */
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => 
  {
    const handleNavigationTiming = () => 
    {
      const perfEntries = performance.getEntriesByType("navigation");

      if (perfEntries.length > 0) 
      {
        const navigationEntry = perfEntries[0];

        if (navigationEntry.type === "reload") 
        {
          localStorage.removeItem("isLoggedOut");
          setIsLoggedOut(false);
        }
      }
    };

    window.addEventListener("load", handleNavigationTiming);

    const timeout = setTimeout(() => 
    {
      localStorage.removeItem("isLoggedOut");
      setIsLoggedOut(false);
    }, 3000);

    return () => 
    {
      window.removeEventListener("load", handleNavigationTiming);
      clearTimeout(timeout);
    }
  }, [isLoggedOut]);

  const handleToggleShowPassword = () => 
  {
    setShowPassword(previousShowPassword => !previousShowPassword);
  }

  const handleEmailChange = (event) => 
  {
    if (typeof event.target.value !== "undefined" && event.target.value !== "") 
    {
      const emailPattern = new RegExp(
        /^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!emailPattern.test(event.target.value)) 
      {
        event.target.setCustomValidity("Please enter a valid email address.");
      } 
      else 
      {
        event.target.setCustomValidity("");
      }
    }
  }

  const handleSubmit = async (event) => 
  {
    event.preventDefault();

    setIsLoading(true);
    setAlertError(null);
    setIsLoggedOut(false);

    const email = event.target.elements[0].value.toLowerCase();
    const password = event.target.elements[1].value;

    await axios({
      method: "post",
      url: "/AuthenticateUser",
      data: { 
        email: email, 
        password: password 
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        if (data.login) 
        {
          const { id, name, role, firstLogin } = data || {};
          
          // Validate role securely (ensure only valid roles are accepted)
          if (!rolesMap.has(role)) 
          {
            setAlertError("Invalid Credentials!");
            return;
          }

          dispatch(setUser(id, name, email, role, true));
          // Navigate based on whether it's the user's first login
          navigate(firstLogin ? "/set-new-password" : routeControl[role]?.default);
        }
        else 
        {
          setAlertError(data?.data);
        }
      }
      else if (status === 202)
      {
        setAlertError(data?.data);
      }
      else
      {
        setAlertError("An error occurred while processing your request. Please try again later or contact the site administrator.");
      }
    })
    .catch((error) => 
    {
      console.log("Login Api: ", error);
      setIsLoading(false);
      setAlertError(
        error?.response?.status === 429
          ? "Login attempts exceeded. Your account is temporarily disabled. Please contact the site administrator."
          : "An error occurred while processing your request. Please try again later or contact the site administrator."
      );
    });
  }

  return (
    <div className = "loginContainer">
      <div className = "login">
        <img src = {Logo} alt = "Engro" />

        {isLoggedOut && !alertError && (
          <Alert variant = "success">Logged Out Successfully.</Alert>
        )}

        <h2 className = "mt-0 text-white">Login to Your Account</h2>

        {alertError && <Alert variant = "danger">{alertError}</Alert>}

        <form
          className = "d-flex flex-column gap-3 mt-2"
          autoComplete = "off"
          onSubmit = {handleSubmit}
        >
          <div className = "form-group">
            <input
              type = "email"
              id = "email"
              name = "email"
              autoComplete="new-password"
              className = "form-control"
              placeholder = "Enter Email"
              onChange = {handleEmailChange}
              required
            />
          </div>

          <div className = "form-group password-container">
            <input
              type = {showPassword ? "text" : "password"}
              id = "password"
              name = "password"
              className = "form-control"
              placeholder = "Enter Password"
              required
            />

            {showPassword ? (
              <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {handleToggleShowPassword} />
            ) : (
              <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {handleToggleShowPassword} />
            )}
          </div>

          <div className = "d-flex justify-content-between align-items-center mt-3">
            <div className = "d-flex justify-content-between align-items-center reset-password m-0">
              <span></span>
              <Link to = "/reset-password" className = "login-link">
                Forgot Password?
              </Link>
            </div>

            <Button
              type = "submit"
              variant = "primary"
              disabled = {isLoading}
              className = "login-button log-in custom_btn"
            >
              {isLoading ? (
                <div className = "spinner-border text-info" role = "status" />
              ) : (
                "Log In"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;