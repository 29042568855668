import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReportDataTable from '../../components/ReportDataTable';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import '../../styles/general.scss';

const DealNo = ({ isLoading, selectedTab, setActiveTab, setSelectedDeal, setSelectedPFI, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut }) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};

  /* CURRENT USER VARIABLES */
  const currentUser = useSelector(state => state.user);

  /* DATATABLE VARIABLES */
  const [dealData, setDealData] = useState([]);
  const [newDeal, setNewDeal] = useState({});
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);

      if (Object.keys(newDeal).length === 0)
      {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
      }

      await axios({
        method: "post",
        url: "/GetDealData",
        data: {
          business_unit_id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const dealData = data?.deal_data || [];
          setDealData(dealData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.error("Get Deal Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedBusinessUnit) 
    {
      fetchData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedBusinessUnit, toggleDisplayData]);

  const handleGenerateDealNo = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "POST",
      url: "/GenerateDealNo",
      data: {
        business_unit_id: selectedBusinessUnit?.id,
        user_id:currentUser?.user_id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setNewDeal(data);
        setAlertMessage(`New deal created successfully: ${data?.value}`);
        setAlertType("success");
        setShowAlert(true);
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.error("Generate Deal No. Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleGeneratePFINo = async (rowData) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const { deal_id, "Deal No.": dealNo } = rowData;
    const selectedDeal = { id: deal_id, value: dealNo };

    await axios({
      method: "POST",
      url: "/AddDealPFI",
      data: {
        deal_no: selectedDeal?.id,
        user_id: currentUser?.user_id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setSelectedDeal(selectedDeal);
        setSelectedPFI(data);
        setNewDeal({});

        if (selectedTab) 
        {
          setActiveTab(selectedTab);
        } 
        else 
        {
          setActiveTab("Deal Header");
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.error("Generate PFI Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleEditDeal = (rowData) =>
  {
    const { deal_id, deal_pfi_id, "Deal No.": dealNo, "PFI No.": pfiNo } = rowData;
    
    setSelectedDeal({ id: deal_id, value: dealNo });
    setSelectedPFI({ id: deal_pfi_id, value: pfiNo.slice(dealNo.length) });

    if (selectedTab) 
    {
      setActiveTab(selectedTab);
    } 
    else 
    {
      setActiveTab("Deal Header");
    }
  }

  return (
    <div className = "d-flex w-100 flex-column gap-2 deal-no justify-content-center py-3 px-2">
      <div className = "d-flex justify-content-between align-items-center">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Deals
        </Typography>

        <button
          className = "btn btn-primary generate-button ml-auto"
          onClick = {handleGenerateDealNo}
          disabled = {isLoading || !selectedBusinessUnit}
        >
          Generate Deal No.
        </button>
      </div>

      <LocalizationProvider dateAdapter = {AdapterDayjs}>
        <ReportDataTable
          title = "Deals"
          isLoading = {isLoading} 
          reportData = {dealData}
          isBusinessUnitSelected = {true}
          businessUnitId = {selectedBusinessUnit?.id}
          newDeal = {newDeal}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          handleAddNewPFI = {handleGeneratePFINo}
          handleEditDeal = {handleEditDeal}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DealNo;