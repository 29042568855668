import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routeConfig from './routeConfig';

const withAuth = (Component) => (allowedRoles) => (props) => 
{
  const navigate = useNavigate();
  const location = useLocation();

  /* AUTHORIZATION VARIABLES */
  const isAuthenticated = useSelector(state => state.user.login);
  const role = useSelector(state => state.user.role);

  /* ROUTE VARIABLES */
  const [isValidRoute, setIsValidRoute] = useState(false);

  useEffect(() => 
  {
    const currentPath = location.pathname;
  
    const isPathValid = () => 
    {
      if (typeof routeConfig[role]?.[currentPath] === "string") 
      {
        return Boolean(routeConfig[role][currentPath]);
      } 
      else 
      {
        const regexRoutes = Object.values(routeConfig[role] || {});
        const isValid = regexRoutes.some(route =>
          route && typeof route !== "string" && typeof route.test === 'function' && route.test(currentPath)
        );
        
        return isValid;
      }
    }

    // If user is authenticated and on the wrong path then redirect user to the default route of the user's role
    if (isAuthenticated && (!allowedRoles.includes(role) || !isPathValid())) 
    {
      setIsValidRoute(false);
    
      const defaultRoute = routeConfig[role]?.default;
      navigate(defaultRoute);
    }
    // If user is not authenticated and on the wrong path then redirect user to the login page
    else if (!isAuthenticated && !routeConfig.login[currentPath]) 
    {
      setIsValidRoute(false);
      
      const loginDefaultRoute = routeConfig.login?.default;
      navigate(loginDefaultRoute);
    }
    // If top two conditions not met then route user to pathname
    else 
    {
      setIsValidRoute(true);
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname, isAuthenticated, role]);

  return isValidRoute ? <Component {...props} /> : null;
}

export default withAuth;