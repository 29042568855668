import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getPermissions } from '../../lib/accessControl';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import '../../styles/general.scss';

const DealNo = ({ isLoading, selectedTab, setActiveTab, setSelectedDeal, setSelectedPFI, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut }) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};

  /* CURRENT USER VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, location?.pathname);

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* DATATABLE VARIABLES */
  const [dealData, setDealData] = useState([]);
  const [newDeal, setNewDeal] = useState({});
  const [toggleDisplayData, setToggleDisplayData] = useState(false);
  const tableRef = useRef();
  
  /* DATATABLE ROW VARIABLES */
  const [rowData, setRowData] = useState({});

  /* DATATABLE GLOBAL FILTER VARIABLES */
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  /* DIALOG VARIABLES */
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);

      if (Object.keys(newDeal).length === 0)
      {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
      }

      await axios({
        method: "post",
        url: "/GetDealData",
        data: {
          business_unit_id: selectedBusinessUnit?.id,
          filter_value: globalFilterValue
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const dealData = data?.deal_data || [];
          setDealData(dealData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.error("Get Deal Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedBusinessUnit) 
    {
      fetchData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedBusinessUnit, toggleDisplayData]);

  /* const extractTableRows = (rows) => 
  {
    const selectedBusinessUnitId = selectedBusinessUnit?.id || null;
    const firstColumn = [2, 3, 5].includes(selectedBusinessUnitId) ? "Deal No." : "Supplier Contract";

    return rows.map(row => 
    {
      // Extract subRows, inheriting data from the parent row
      const processedSubRows = (row.subRows || []).map(subRow => ({
        ...subRow.original,
        [firstColumn]: row.original[firstColumn] // Assign value from parent row to subrow
      }));

      // Return parent row with processed subRows
      return {
        ...row.original,
        subRows: processedSubRows
      };
    });
  } */

  const handleGenerateDealNo = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "POST",
      url: "/GenerateDealNo",
      data: {
        business_unit_id: selectedBusinessUnit?.id,
        user_id: currentUser?.id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setNewDeal(data);
        setAlertMessage(`New deal created successfully: ${data?.value}`);
        setAlertType("success");
        setShowAlert(true);
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.error("Generate Deal No. Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleGeneratePFINoWrapper = (rowData) =>
  {
    setRowData(rowData);
    setShowConfirmationDialog(true);
  }

  const handleGeneratePFINo = async () => 
  {
    setShowConfirmationDialog(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const { deal_id, "Deal No.": dealNo } = rowData;
    const selectedDeal = { id: deal_id, value: dealNo };

    await axios({
      method: "POST",
      url: "/AddDealPFI",
      data: {
        business_unit_id: selectedBusinessUnit?.id,
        deal_no: selectedDeal?.id,
        user_id: currentUser?.id
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setSelectedDeal(selectedDeal);
        setSelectedPFI(data);
        setNewDeal({});

        if (selectedTab) 
        {
          setActiveTab(selectedTab);
        } 
        else 
        {
          setActiveTab("Deal Header");
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.error("Generate PFI Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleEditDeal = (rowData) =>
  {
    const { deal_id, deal_pfi_id, "Deal No.": dealNo, "PFI No.": pfiNo } = rowData;
    
    setSelectedDeal({ id: deal_id, value: dealNo });
    setSelectedPFI({ id: deal_pfi_id, value: pfiNo.slice(dealNo.length) });

    if (selectedTab) 
    {
      setActiveTab(selectedTab);
    } 
    else 
    {
      setActiveTab("Deal Header");
    }
  }

  const handleGlobalFilter = async (rowData) =>
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
    
    await axios({
      method: "post",
      url: "/FilterTableData",
      data: { 
        pathname: location?.pathname,
        business_unit_id: selectedBusinessUnit?.id,
        table_data: rowData,
        filter_value: globalFilterValue
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        tableRef?.current?.setFilteredData(data || []);
      }
      else
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Save Changes Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseConfirmationDialog = () =>
  {
    setShowConfirmationDialog(false);
  }

  return (
    <div className = "d-flex w-100 flex-column gap-2 deal-no justify-content-center py-3 px-2">
      <div className = "d-flex justify-content-between align-items-center">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Deals
        </Typography>

        <button
          className = "btn btn-primary generate-button ml-auto"
          onClick = {handleGenerateDealNo}
          disabled = {!isWritable || isLoading || !selectedBusinessUnit}
        >
          Generate Deal No.
        </button>
      </div>

      <Dialog open = {showConfirmationDialog} onClose = {handleCloseConfirmationDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Are you sure you want to proceed with adding a new PFI?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn_secondary custom_btn"
            onClick = {handleGeneratePFINo}
          >
            Add
          </button>

          <button
            type = "button"
            variant = "contained"
            className = "btn btn-danger custom_btn"
            onClick = {handleCloseConfirmationDialog}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      <LocalizationProvider dateAdapter = {AdapterDayjs}>
        <MaterialReactDataTable
          ref = {tableRef}
          title = "Deals"
          isWritable = {isWritable}
          isLoading = {isLoading}
          isBusinessUnitSelected = {true}
          businessUnitId = {selectedBusinessUnit?.id}
          newDeal = {newDeal} 
          globalFilterValue = {globalFilterValue}
          reportData = {dealData}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          setGlobalFilterValue = {setGlobalFilterValue}
          handleAddNewPFI = {handleGeneratePFINoWrapper}
          handleEditDeal = {handleEditDeal}
          handleGlobalFilter = {handleGlobalFilter}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DealNo;