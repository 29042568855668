import { memo, useEffect, useRef, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleGlobalFilterButton } from 'material-react-table';
import { RenderCustomCell } from './DataTableCustomCell';
import Sketch from '@uiw/react-color-sketch';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import SaveIcon from '@mui/icons-material/Save';

const ReportDataTable = ({ title = "", isLoading = false, isTabChanged = false, isBusinessUnitSelected = false, businessUnitId = null, newDeal = {}, isReportExportable = false, reportData = {}, setIsTabChanged = () => {}, handleExportRows = () => {}, handleViewDocument = () => {}, handleRecordStatusChange = () => {}, handleAddNewPFI = () => {}, handleEditDeal = () => {}, handleSaveChanges = () => {} }) => 
{
    /* DATATABLE VARIABLES */
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [isDataChanged, setIsDataChanged] = useState(false);
    const resetButtonRef = useRef(null);

    /* COLOR PICKER VARIABLES */
    const [anchorEl, setAnchorEl] = useState(null);
    const showColorPicker = Boolean(anchorEl);
    
    useEffect(() =>
    {
        let reportColumns = reportData?.columns || [];
        const { rows: reportRows = [], data_types: reportColumnDataTypes = {} } = reportData;
        const tempColumns = [];

        reportColumns = reportColumns.filter(column => !["id", "deal_id", "deal_pfi_id", "tranche_id", "coo_id", "revision_number", "isApproved", "rowColor", "tranche_product_id"].includes(column)) || [];

        if (title === "Deals" && [2, 3, 5].includes(businessUnitId))
        {
            reportColumns = reportColumns.filter(column => column !== "Product Grade");
        }

        for (let i = 0; i < reportColumns.length; i++)
        {
            const column = reportColumns[i];
            const dataType = reportColumnDataTypes[column];
            const width = 150;

            if (dataType.includes("object"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => originalRow[column],
                    id: column,
                    header: column === "Deal No" ? "Deal No." : column,
                    size: width,
                    filterVariant: 'multi-select',
                    filterFn: 'multiSelect',
                    visibleInShowHideMenu: !(title === "Deals" && [2, 3, 5].includes(businessUnitId) && column === "Product Grade"),
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const string = row.original?.[column];

                        return (
                            <RenderCustomCell 
                                value = {string}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("int") || dataType.includes("float"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => originalRow[column],
                    id: column,
                    header: column,
                    size: width,
                    filterVariant: 'range',
                    filterFn: 'between',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const number = isNaN(parseFloat(row.original?.[column])) ? null : parseFloat(row.original?.[column]);

                        return (
                            <RenderCustomCell 
                                value = {number}  
                                width = {col.getSize()} 
                                isNumber = {true} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("datetime"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => new Date(originalRow[column]),
                    id: column,
                    header: column,
                    size: width,
                    filterVariant: 'date-range',
                    filterFn: 'betweenInclusive',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, cell, row }) => {
                        const date = row.original[column] ? cell.getValue().toLocaleDateString() : null;
                        
                        return (
                            <RenderCustomCell 
                                value = {date}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
        }

        if (!(title === "Approve Documents" && !isTabChanged)) 
        {
            resetButtonRef?.current?.click();
        }

        setColumns(tempColumns);
        setData(reportRows);
        setSelectedRow({});
        setIsDataChanged(false);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [reportData]);

    const handleChangeRowColor = (color) =>
    {
        setData((previousData) => 
        {
            const updatedData = [...previousData];
            const updatedSubRows = [...updatedData[selectedRow.parentId]?.subRows];
            const currentRow = updatedSubRows[selectedRow.index];
            const previousRowColor = currentRow?.previousRowColor || currentRow.rowColor;
            const isUpdated = previousRowColor?.toLowerCase() !== color?.toLowerCase();
        
            updatedSubRows[selectedRow.index] = {
                ...currentRow,
                rowColor: color,
                previousRowColor,
                isUpdated
            };
            updatedData[selectedRow.parentId] = {
                ...updatedData[selectedRow.parentId],
                subRows: updatedSubRows
            };
            setIsDataChanged(isUpdated);
        
            return updatedData;
        });        
    }

    const handleShowColorPicker = (event, row) => 
    {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    }
    
    const handleCloseColorPicker = () => 
    {
        setAnchorEl(null);
        setSelectedRow({});
    }
    
    const handleResetTable = (table) =>
    {
        table.resetColumnFilters();
        table.resetExpanded();
        setAnchorEl(null);
    }
    
    const table = useMaterialReactTable({
        columns,
        data,
        enableFacetedValues: true,
        enableColumnResizing: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnVirtualization: true,
        enableRowVirtualization: true,
        enableColumnActions: false,
        enableExpanding: true,
        enableExpandAll: false,
        enableStickyHeader: true,
        filterFromLeafRows: true,
        paginateExpandedRows: false,
        enableFilterMatchHighlighting: true,
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        autoResetAll: title === "Approve Documents" ? isTabChanged : true,
        enableRowActions: (title === "Deals" || title === "Morning Report" || title === "Approve Documents") && columns?.length > 0,
        columnVirtualizerOptions: { overscan: columns.length - 1 },
        state: { showProgressBars: isLoading },
        initialState: { 
            density: 'compact',
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 20 },
            columnPinning: { left: ['mrt-row-expand'] }
        },
        getSubRows: (row) => row.subRows,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <Button 
                    ref = {resetButtonRef} 
                    className = "hidden" 
                    onClick = {() => handleResetTable(table)}
                ></Button>

                {isReportExportable && (
                    <Button
                        startIcon = {<FileDownloadIcon />}
                        disabled = {table.getPrePaginationRowModel().rows.length === 0}
                        onClick = {() => handleExportRows(table.getPrePaginationRowModel().rows)}
                    >
                        Export {title}
                    </Button>
                )}
            </div>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                {title === "Morning Report" && (
                    <IconButton
                        title = "Save Changes"
                        disabled = {!isDataChanged}
                        onClick = {() => handleSaveChanges(table.getPrePaginationRowModel().rows)}
                    >
                        <SaveIcon />
                    </IconButton>
                )}

                <IconButton
                    title = "Clear Filters"
                    onClick = {() => table.resetColumnFilters()}
                >
                    <FilterAltOffIcon />
                </IconButton>

                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ToggleGlobalFilterButton table = {table} />
                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ShowHideColumnsButton table = {table} />
            </>
        ),
        renderEmptyRowsFallback: () => (
            <p className = "empty-table">
                {isLoading ? "Loading data, please wait..." : !isBusinessUnitSelected ? "Select a business unit to display the table data." : "No records to display."}
            </p>
        ),
        renderRowActions: ({ row }) => {
            let rowData = row.original || {};
            const isSubRow = row.depth > 0;

            if (title === "Deals" && isSubRow)
            {
                const parentRowData = row.getParentRow().original;
                const { deal_id, "Deal No.": dealNumber } = parentRowData;

                rowData = { ...rowData, "Deal No.": dealNumber, deal_id };                
            }

            return (
                <div className = {`${title !== "Approve Documents" ? 'mx-auto' : ''}`}>
                    {isSubRow ? (  
                        <Box className = "d-flex justify-content-center align-items-center w-100 gap-2 h-full">
                            {title === "Morning Report" ? (
                                <>
                                    <IconButton 
                                        color = "primary" 
                                        size = "small"
                                        onClick = {(event) => handleShowColorPicker(event, row)}
                                    >
                                        <FormatColorFillIcon 
                                            fontSize = "small" 
                                            sx = {{ color: '#065886' }}
                                        />
                                    </IconButton>
        
                                    <Popover 
                                        open = {showColorPicker}
                                        anchorEl = {anchorEl}
                                        anchorOrigin = {{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin = {{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        onClose = {handleCloseColorPicker}
                                    >
                                        <Sketch
                                            color = {rowData?.rowColor}
                                            disableAlpha = {true}
                                            onChange = {(color) => handleChangeRowColor(color.hex)}
                                        />
                                    </Popover>
                                </>
                            ) : title === "Approve Documents" ? (
                                <>
                                    <Tooltip title = "View">
                                        <IconButton 
                                            color = "primary" 
                                            size = "small"
                                            onClick = {() => handleViewDocument(rowData)}
                                        >
                                            <VisibilityIcon 
                                                fontSize = "small" 
                                                sx = {{ color: '#065886' }}
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Button
                                        className = "capitalize"
                                        color = {rowData?.isApproved ? 'error' : 'success'}
                                        onClick = {() => 
                                        {
                                            handleRecordStatusChange(rowData);
                                            setIsTabChanged(false);
                                        }}
                                    >
                                        <strong>
                                            {rowData?.isApproved ? 'Disapprove' : 'Approve'}
                                        </strong>
                                    </Button>
                                </>
                            ) : (
                                <Tooltip title = "Edit">
                                    <IconButton 
                                        color = "primary" 
                                        size = "small"
                                        onClick = {() => handleEditDeal(rowData)}
                                    >
                                        <EditIcon 
                                            fontSize = "small"
                                            sx = {{ color: '#065886' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    ) : title === "Deals" && (
                        <Box className = "d-flex justify-content-center align-items-center w-100 gap-2 h-full">
                            <Tooltip title = "Add">
                                <IconButton 
                                    color = "primary" 
                                    size = "small"
                                    onClick = {() => handleAddNewPFI(rowData)}
                                >
                                    <AddIcon 
                                        fontSize = "small"
                                        sx = {{ color: '#065886' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </div>
            );
        },
        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 30
            },
            'mrt-row-actions': {
                size: 120,
                grow: false,
                visibleInShowHideMenu: false
            }
        },
        filterFns: {
            multiSelect: (row, columnId, filterValue) => 
            {
                if (!filterValue || filterValue.length === 0) 
                {
                    return true;
                }
                
                return filterValue.includes(row.getValue(columnId));
            }
        },
        muiTableProps: {
            sx: {
                caption: {
                    captionSide: 'top'
                }
            }
        },
        muiTableContainerProps: {
            sx: { 
                height: 'calc(100vh - 270px)' 
            }
        },
        muiTableBodyProps: {
            sx: { 
                height: 'calc(100vh - 270px)' 
            }
        },
        muiTableHeadProps: {
            sx: {
                '& .MuiTableCell-head:first-of-type > div': {
                    display: 'none'
                },
                '& .MuiTableCell-head:nth-last-of-type(2) > div': {
                    justifyContent: 'center'
                },
                '& .MuiTableCell-head': {
                    paddingTop: 0
                }
            }
        },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(81, 81, 81, .5)',
                fontSize: '13px',
                paddingTop: 0,
                paddingBottom: 0,
                '& .Mui-TableHeadCell-Content-Wrapper': {
                    whiteSpace: 'unset'
                }
            }
        },
        muiTableBodyRowProps: ({ row }) => {
            const isTotalRow = row.original["Supplier Contract"]?.includes("Total");
            const isExpanded = row.getCanExpand() && row.getIsExpanded();
            const isSubRow = row.depth > 0;
            const backgroundColor = (() => {
                if (title === "Morning Report" && isSubRow) return row.original?.rowColor || "transparent";
                if (newDeal?.id && row.original?.deal_id === newDeal.id) return "#c7dcf5";
                if (isExpanded) return "#d7d7d8";
                return isTotalRow ? "#d7e5ef" : "transparent";
            })();

            return {
                sx: {
                    height: '25px',
                    fontSize: '13px',
                    backgroundColor
                }
            };
        },
        muiTableBodyCellProps: ({ column, row }) => {
            const { 
                "Transaction Status": transactionStatusRaw, 
                "TAT Cust. PFI": tatCustPFI, 
                "Status Cust. LC": statusCustLCRaw,
                "Aging/TAT Cust. LC": agingTATCustLC,
                "Status Sup. LC": statusSupLCRaw,
                "Aging/TAT Sup. LC": agingTATSupLC,
                "Aging/TAT Sup. Docs (Copy)": agingTATSupDocsCopy,
                "Aging/TAT Cust. Docs (Copy)": agingTATCustDocsCopy,
                "Aging/TAT Sup. Docs (Original)": agingTATSupDocsOriginal,
                "Aging/TAT Cust. Docs (Original)": agingTATCustDocsOriginal,
                "Supplier Contract": supplierContract
            } = row.original;    
            const statusCustLC = statusCustLCRaw?.toLowerCase();
            const statusSupLC = statusSupLCRaw?.toLowerCase();
            const transactionStatus = transactionStatusRaw?.toLowerCase();
            const isSubRow = row.depth > 0;

            let color = "black";
            let backgroundColor = "transparent";
            let fontWeight = "normal";
        
            if (column.id === "Transaction Status" && transactionStatus === "transaction completed") 
            {
                color = "white";
                backgroundColor = "#92D050";
            } 
            else if (column.id === "Status Cust. LC" || column.id === "Status Sup. LC") 
            {
                if (statusCustLC === "pending" || statusSupLC === "pending") 
                {
                    color = "#9C0006";
                    backgroundColor = "#FFC7CE";
                } 
                else if (statusCustLC === "received" || statusSupLC === "transmitted")
                {
                    color = "#006100";
                    backgroundColor = "#C6EFCE";
                }
            }
            else if (
                (column.id === "TAT Cust. PFI" && tatCustPFI > 1) || 
                (column.id === "Aging/TAT Cust. LC" && agingTATCustLC > 10) ||
                (column.id === "Aging/TAT Sup. LC" && agingTATSupLC > 3) ||
                (column.id === "Aging/TAT Sup. Docs (Copy)" && agingTATSupDocsCopy > 10) ||
                (column.id === "Aging/TAT Cust. Docs (Copy)" && agingTATCustDocsCopy > 11) ||
                (column.id === "Aging/TAT Sup. Docs (Original)" && agingTATSupDocsOriginal > 21) ||
                (column.id === "Aging/TAT Cust. Docs (Original)" && agingTATCustDocsOriginal > 30))
            {
                color = "white";
                backgroundColor = "red";
            }
            else if (supplierContract?.includes("Total"))
            {
                fontWeight = "600";
            }
            else if (isSubRow && column.id === "Deal No.") 
            {
                color = "transparent";
            }
            else if (isSubRow && column.id === "Status")
            {
                const status = row.original.Status;

                color = "white";
                backgroundColor = status === "Approved" ? "#00b242" : "#fe0000";
            }
            else if (["mrt-row-actions", "mrt-row-expand"].includes(column.id))
            {
                backgroundColor = "white";
            }
        
            return {
                sx: {
                    border: '1px solid rgba(81, 81, 81, .5)',
                    opacity: 1,
                    color,
                    backgroundColor,
                    fontWeight
                }
            };
        },
        muiFilterAutocompleteProps: {
            sx: {
                '& .MuiAutocomplete-input': {
                    width: '100% !important'
                }
            }
        },
        muiLinearProgressProps: {
            sx: {
                color: 'secondary',
                variant: 'indeterminate'
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: true,
            showLastButton: true
        }
    });

    return (
        <div style = {{ height: '100%' }}>
            <MaterialReactTable table = {table} />
        </div>
    );
}

export default memo(ReportDataTable);