import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

const MenuList = ({ menuConfig = [], allowedRoutes = [], pathname = null, isDealSubMenuOpen = false, isPolymerDealSubMenuOpen = false, isEntitySubMenuOpen = false, isReportSubMenuOpen = false, handleMenuClick = () => {}, handleSubMenuClick = () => {} }) => 
{
    const filterSubMenu = (subMenu) => 
    {
        return subMenu?.filter((item) =>
            allowedRoutes?.includes(item.path) || !item.path
        ) || [];
    }

    return (
        <Menu className = "mt-3">
            {menuConfig.map((menu) => {
                const isSubMenuOpen =
                    menu.key === "deals" ? isDealSubMenuOpen || pathname === "/" || (pathname.includes("/deals") && !pathname.includes("/entities/deals")) :
                    menu.key === "entities" ? isEntitySubMenuOpen || pathname?.includes("entities") :
                    menu.key === "reports" ? isReportSubMenuOpen || pathname?.includes("reports") :
                    false;

                const filteredSubMenu = filterSubMenu(menu.subMenu);

                return (
                    <div key = {menu.key}>
                        {filteredSubMenu.length > 0 && menu.subMenu ? (
                            <SubMenu
                                key = {menu.key}
                                icon = {menu.icon}
                                label = {menu.label}
                                open = {isSubMenuOpen}
                                onOpenChange = {() => handleSubMenuClick(menu.key)}
                            >
                                {filteredSubMenu.map((item) => 
                                {
                                    if (item.subMenu) 
                                    {
                                        return (
                                            <SubMenu
                                                key = {item.key}
                                                label = {item.label}
                                                open = {isPolymerDealSubMenuOpen || pathname.includes("deals/polymers") || pathname.includes("deals/sblc")}
                                                onOpenChange = {() => handleSubMenuClick(item.key)}
                                            >
                                                {item.subMenu.map((nestedItem) => (
                                                    <MenuItem
                                                        key = {nestedItem.path}
                                                        active = {pathname === nestedItem.path}
                                                        onClick = {() => handleMenuClick(nestedItem.path, {
                                                            selectedBusinessUnit: nestedItem.businessUnit
                                                        })}
                                                    >
                                                        {nestedItem.label}
                                                    </MenuItem>
                                                ))}
                                            </SubMenu>
                                        );
                                    } 
                                    else
                                    {
                                        return (
                                            <MenuItem
                                                key = {item.path}
                                                active = {pathname === item.path}
                                                onClick = {() => handleMenuClick(item.path, {
                                                    selectedBusinessUnit: item.businessUnit
                                                })}
                                            >
                                                {item.label}
                                            </MenuItem>
                                        );
                                    }
                                })}
                            </SubMenu>
                        ) : allowedRoutes.includes(menu.path) && (
                            <MenuItem
                                key = {menu.path}
                                icon = {menu.icon}
                                active = {pathname === menu.path}
                                className = "single_dropdown"
                                onClick = {() => handleMenuClick(menu.path, {}, true)}
                            >
                                {menu.label}
                            </MenuItem>
                        )}
                    </div>
                );
            })}
        </Menu>
    );
}

export default MenuList;