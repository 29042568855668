export const setUser = (id, name, email, role, accessibleModules, login) => ({
    type: "SET_USER",
    payload: {
        user_id: id,
        name: name,
        email: email,
        role: role,
        access: accessibleModules,
        login: login
    }
});