import { BrowserRouter } from 'react-router-dom';
import { TabProvider } from './hooks/useTab';
import RouterLinks from './routes/RouterLinks';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => 
{
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <TabProvider>
          <RouterLinks />
        </TabProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;