import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../routes/RouterLinks';
import SideBar from './SideBar';
import axios from 'axios';

const SidebarNavigation = ({ component, logOut }) => 
{
    const currentUser = useContext(UserContext);
    const navigate = useNavigate();

    /* LIST VARIABLES */
    const [businessUnits, setBusinessUnits] = useState([]);

    useEffect(() => 
    {
        const fetchLists = async () => 
        {
            axios({
                method: "get",
                url: "/GetBusinessUnitList"
            })
            .then((response) => 
            {
                const { status, data } = response;

                if (status === 200) 
                {
                    const businessUnits = data?.business_units || [];
                    setBusinessUnits(businessUnits);
                }
            })
            .catch((error) => 
            {
                if (error?.response?.status === 403) 
                {
                    logOut();
                }
                
                console.log("Get Business Units Api: ", error);
            });
        }
    
        fetchLists();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() =>
    {
        const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;
        
        if (businessUnits.length > 0 && !isLoggedIn)
        {
            const firstUnit = businessUnits[0];
        
            localStorage.setItem("isLoggedIn", JSON.stringify(true));
            navigate(`/deals/${firstUnit?.value?.toLowerCase()}`, {
                state: { selectedBusinessUnit: firstUnit }
            });
        }
    
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [businessUnits]);
    
    return (
        <SideBar 
            currentUser = {currentUser} 
            component = {component} 
            businessUnits = {businessUnits}
            logOut = {logOut} />
    );
}

export default SidebarNavigation;