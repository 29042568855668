import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Sidebar } from 'react-pro-sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import { FaTableCells } from 'react-icons/fa6';
import { FaClipboardList, FaUser } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';
import { IoDocumentsSharp } from 'react-icons/io5';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import { RoleLabel } from '../utility/roles';
import { allowedRoutesByRole } from '../utility/routes';
import useTab from '../hooks/useTab';
import SidePanel from './SidePanel';
import MenuList from '../components/MenuList';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import Logo from '../images/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/sidebar.css';

const SideBar = ({ currentUser, component, businessUnits = [], logOut = () => {} }) => 
{
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /* AUTHORIZATION VARIABLES */
  const getAllowedRoutes = (role) => allowedRoutesByRole[role] || allowedRoutesByRole.default;
  const role = useSelector(state => state.user.role);
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const allowedRoutes = useMemo(() => getAllowedRoutes(role), [role]);

  /* TAB VARIABLES */
  const { setActiveTab, setSelectedDeal, setSelectedPFI } = useTab();

  /* ACCOUNT MENU VARIABLES */
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /* MOBILE VIEW VARIABLES */
  const [collapsed, setCollapsed] = useState(false);
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(pathname);
  const toggleButtonRef = useRef(null);

  /* BUSINESS UNIT VARIABLES */
  const polymerBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "Polymers") || null;
  const sblcBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "SBLC") || null;
  
  /* MENU VARIABLES */
  const menuConfig = useMemo(() => 
  {
    const hasPolymers = polymerBusinessUnit !== null;
    const hasSBLC = sblcBusinessUnit !== null;
  
    return [
      {
        key: "deals",
        label: "Deals",
        icon: <FaClipboardList />,
        subMenu: [
          ...businessUnits
            .filter((businessUnit) => !["Polymers", "SBLC"].includes(businessUnit.value))
            .map((businessUnit) => ({
              path: `/deals/${businessUnit.value.toLowerCase()}`,
              label: businessUnit.value,
              businessUnit: businessUnit
            })),
          // Only show the Polymers sub-menu if both Polymers and SBLC are present
          hasPolymers && hasSBLC ? {
            key: "polymers",
            label: "Polymers",
            subMenu: [
              { 
                path: "/deals/polymers", 
                label: "Polymers",
                businessUnit: polymerBusinessUnit
              },
              { 
                path: "/deals/sblc", 
                label: "Polymers - SBLC",
                businessUnit: sblcBusinessUnit
              }
            ]
          } : null // If both are not present, exclude this sub-menu
        ].filter(item => item !== null) // Filter out null (empty) sub-menu
      },
      {
        key: "entities",
        label: "Entities",
        icon: <FaTableCells />,
        subMenu: [
          { path: "/entities/banks", label: "Banks" },
          { path: "/entities/deals", label: "Deals" },
          { path: "/entities/deal-pfis", label: "Deal PFIs" },
          { path: "/entities/dropdowns", label: "Dropdowns" },
          { path: "/entities/file-types", label: "File Types" },
          { path: "/entities/products", label: "Products" },
          { path: "/entities/sblc", label: "SBLC" },
          { path: "/entities/tenors", label: "Tenors" },
          { path: "/entities/traders", label: "Traders" },
          { path: "/entities/vendors", label: "Vendors" }
        ].filter(item => item.path) // Filter out invalid paths if any
      },
      {
        key: "reports",
        label: "Reports",
        icon: <RiPagesLine />,
        subMenu: [
          { path: "/reports/deal-report", label: "Deal Report" },
          { path: "/reports/finance-report", label: "Finance Report" },
          { path: "/reports/morning-report", label: "Morning Report" }
        ].filter(item => item.path) // Filter out invalid paths if any
      },
      {
        key: "documents",
        label: "Approve Documents",
        path: "/approve-documents",
        icon: <IoDocumentsSharp />
      },
      {
        key: "user-management",
        label: "Create User",
        path: "/create-user",
        icon: <FaUser />
      }
    ];
  }, [businessUnits, polymerBusinessUnit, sblcBusinessUnit]);
  
  /* SUB-MENU VARIABLES */
  const [isDealSubMenuOpen, setIsDealSubMenuOpen] = useState(pathname.includes("deals"));
  const [isPolymerDealSubMenuOpen, setIsPolymerDealSubMenuOpen] = useState(pathname.includes("deals/polymers") || pathname.includes("deals/sblc"));
  const [isEntitySubMenuOpen, setIsEntitySubMenuOpen] = useState(pathname.includes("entities"));
  const [isReportSubMenuOpen, setIsReportSubMenuOpen] = useState(pathname.includes("reports"));

  useEffect(() => 
  {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => 
  {
    if (businessUnits.length > 0 && (pathname === "/" || (pathname.includes("deals") && !pathname.includes("entities")))) 
    {
      const tokenizedPathname = pathname.split("/");
      const selectedBusinessUnit = tokenizedPathname.length !== 3
      ? tokenizedPathname.length === 4
        ? businessUnits.find(({ value }) => value.toLowerCase() === `${tokenizedPathname?.[2]?.toLowerCase()}/${tokenizedPathname?.[3]?.toLowerCase()}`)
        : businessUnits?.[0]
      : businessUnits.find(({ value }) => value.toLowerCase() === tokenizedPathname?.[2]?.toLowerCase());

      setActiveTab("Deal No.");
      setSelectedDeal(null);
      navigate(`/deals/${selectedBusinessUnit?.value?.toLowerCase()}`, {
        state: { selectedBusinessUnit }
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [businessUnits, pathname]);

  useEffect(() => 
  {
    const timeout = setTimeout(() => 
    {
      if (isDealSubMenuOpen && !selectedMenuItem.includes("deals")) 
      {
        setIsDealSubMenuOpen(false);
      }
      else if (isPolymerDealSubMenuOpen && !selectedMenuItem.includes("deals/polymers") && !selectedMenuItem.includes("deals/sblc"))
      {
        setIsPolymerDealSubMenuOpen(false);
      }
      else if (isEntitySubMenuOpen && !selectedMenuItem.includes("entities"))
      {
        setIsEntitySubMenuOpen(false);
      }
      else if (isReportSubMenuOpen && !selectedMenuItem.includes("reports"))
      {
        setIsReportSubMenuOpen(false);
      }
    }, 10000);

    return () => clearTimeout(timeout);
  }, [selectedMenuItem, isDealSubMenuOpen, isPolymerDealSubMenuOpen, isEntitySubMenuOpen, isReportSubMenuOpen]);

  const handleMenuClick = (menuItem, state = {}, isNotSubMenu = false) => 
  {
    setSelectedMenuItem(menuItem);
    setActiveTab("Deal No.");
    setSelectedDeal(null);
    setSelectedPFI(null);
    setOpenSidePanel(false);
    navigate(menuItem, { state });

    if (isNotSubMenu)
    {
      setIsDealSubMenuOpen(false);
      setIsPolymerDealSubMenuOpen(false);
      setIsEntitySubMenuOpen(false);
      setIsReportSubMenuOpen(false);
    }
  }

  const handleDealSubMenuClick = () =>
  {
    setIsDealSubMenuOpen(!isDealSubMenuOpen);
    setIsPolymerDealSubMenuOpen(isDealSubMenuOpen ? isPolymerDealSubMenuOpen : false);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(false);
  }

  const handlePolymerDealSubMenuClick = () =>
  {
    setIsPolymerDealSubMenuOpen(!isPolymerDealSubMenuOpen);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(false);
  }

  const handleEntitySubMenuClick = () =>
  {
    setIsDealSubMenuOpen(false);
    setIsPolymerDealSubMenuOpen(false);
    setIsEntitySubMenuOpen(!isEntitySubMenuOpen);
    setIsReportSubMenuOpen(false);
  }

  const handleReportSubMenuClick = () =>
  {
    setIsDealSubMenuOpen(false);
    setIsPolymerDealSubMenuOpen(false);
    setIsEntitySubMenuOpen(false);
    setIsReportSubMenuOpen(!isReportSubMenuOpen);
  }

  const handleSubMenuClick = (menuKey) =>
  {
    if (menuKey === "deals")
    {
      handleDealSubMenuClick();
    }
    else if (menuKey === "polymers")
    {
      handlePolymerDealSubMenuClick();
    }
    else if (menuKey === "entities")
    {
      handleEntitySubMenuClick();
    }
    else if (menuKey === "reports")
    {
      handleReportSubMenuClick();
    }
  }

  const handleToggleSidePanel = () => 
  {
    setOpenSidePanel(!openSidePanel);
  }

  const handleLogoClick = () => 
  {
    setActiveTab("Deal No.");
    setSelectedDeal(null);
    navigate("/");
  }

  const handleAccountMenuClick = (event, menuItem) => 
  {
    setAnchorEl(event.currentTarget);

    if (menuItem)
    {
      navigate(menuItem);
    }
  }
  
  const handleAccountMenuClose = () => 
  {
    setAnchorEl(null);
  }

  const handleLogout = (event) =>
  {
    event.preventDefault();

    setAnchorEl(null);
    setActiveTab("Deal No.");
    setSelectedDeal(null);
    logOut();
  }

  return (
    <div className = "d-flex">
      {matches ? (
        <SidePanel
          menuConfig = {menuConfig}
          allowedRoutes = {allowedRoutes}
          pathname = {pathname}
          toggleButtonRef = {toggleButtonRef}
          openSidePanel = {openSidePanel}
          isDealSubMenuOpen = {isDealSubMenuOpen}
          isPolymerDealSubMenuOpen = {isPolymerDealSubMenuOpen}
          isEntitySubMenuOpen = {isEntitySubMenuOpen}
          isReportSubMenuOpen = {isReportSubMenuOpen}
          setOpenSidePanel = {setOpenSidePanel}
          handleMenuClick = {handleMenuClick}
          handleSubMenuClick = {handleSubMenuClick}
        />
      ) : (
        <Sidebar collapsed = {collapsed}>
          <div className = "logo_section">
            <img
              src = {Logo}
              alt = "Engro"
              className = "logo"
              onClick = {handleLogoClick}
            />
          </div>

          <MenuList 
            menuConfig = {menuConfig}
            allowedRoutes = {allowedRoutes}
            pathname = {pathname}
            isDealSubMenuOpen = {isDealSubMenuOpen}
            isPolymerDealSubMenuOpen = {isPolymerDealSubMenuOpen}
            isEntitySubMenuOpen = {isEntitySubMenuOpen}
            isReportSubMenuOpen = {isReportSubMenuOpen}
            handleMenuClick = {handleMenuClick}
            handleSubMenuClick = {handleSubMenuClick}
          />
        </Sidebar>
      )}

      <div className = {`content_section ${collapsed ? "collapsed" : ""}`}>
        {matches && openSidePanel && <div className = "overlay" />}

        <div className = {`top_header ${collapsed ? "collapsed" : ""}`}>
          {matches ? (
            <>
              <button
                ref = {toggleButtonRef}
                className = "openbtn"
                onClick = {handleToggleSidePanel}
              >
                ☰
              </button>

              <div className = "logo_section">
                <img
                  src = {Logo}
                  alt = "logo"
                  className = "logo"
                  onClick = {handleLogoClick}
                />
              </div>
            </>
          ) : (
            <button
              className = "btn text-white menu-button m-0 d-flex align-items-center"
              onClick = {() => setCollapsed(!collapsed)}
            >
              {collapsed ? (
                <AiOutlineMenuUnfold className = "header-icon" />
              ) : (
                <AiOutlineMenuFold className = "header-icon" />
              )}
              Menu
            </button>
          )}

          {currentUser && (
            <div className = "profile-container">
              {!matches && (
                <div className = "user-details">
                  <span>{currentUser?.name}</span>
                  <small><RoleLabel role = {role} /></small>
                </div>
              )}

              <Box sx = {{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                  onClick = {handleAccountMenuClick}
                  size = "small"
                  aria-controls = {open ? 'account-menu' : undefined}
                  aria-haspopup = "true"
                  aria-expanded = {open ? 'true' : undefined}
                  sx = {{ padding: 0 }}
                >
                  <Avatar 
                    sx = {{ 
                      width: 38, 
                      height: 38, 
                      color: '#1976d2', 
                      backgroundColor: 'white' 
                    }}
                  >
                    {currentUser?.name?.[0]?.toUpperCase()}
                  </Avatar>
                </IconButton>
              </Box>
              
              <MuiMenu
                anchorEl = {anchorEl}
                id = "account-menu"
                open = {open}
                onClose = {handleAccountMenuClose}
                onClick = {handleAccountMenuClose}
                transformOrigin = {{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin = {{ horizontal: 'right', vertical: 'bottom' }}
                slotProps = {{
                  paper: {   
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                      },
                      '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                      },
                      '& .MuiList-root > li': {
                        paddingTop: 0, 
                        paddingBottom: 0 
                      }
                    }
                  }
                }}
              >
                <MuiMenuItem 
                  onClick = {(event) => handleAccountMenuClick(event, "/edit-profile")}
                  sx = {{
                    gap: '11px'
                  }}
                >
                  <Avatar 
                    sx = {{ 
                      width: '20px !important', 
                      height: '20px !important'
                    }}
                  /> 
                  Profile
                </MuiMenuItem>
                <Divider />
                <MuiMenuItem onClick = {handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize = "small" />
                  </ListItemIcon>
                  Logout
                </MuiMenuItem>
              </MuiMenu>
            </div>
          )}
        </div>

        <div className = "content_inner">{component ? component : ""}</div>
      </div>
    </div>
  );
}

export default SideBar;