import { useEffect, useRef } from 'react';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { MdOutlineArrowBack } from 'react-icons/md';
import { FaTableCells } from 'react-icons/fa6';
import { FaClipboardList, FaUser } from 'react-icons/fa';
import { RiPagesLine } from 'react-icons/ri';
import { IoDocumentsSharp } from 'react-icons/io5';
import useOutsideAlerter from '../hooks/useOutsideAlerter';
import '../styles/components.scss';

const SidePanel = ({ currentUser, toggleButtonRef, activeTab, openSidePanel, businessUnits, polymerBusinessUnit, sblcBusinessUnit, isDealSubMenuOpen, isPolymerDealSubMenuOpen, isEntitySubMenuOpen, isReportSubMenuOpen, setOpenSidePanel, handleMenuClick, handleDealSubMenuClick, handlePolymerDealSubMenuClick, handleEntitySubMenuClick, handleReportSubMenuClick }) => 
{
  const sidePanelRef = useRef(null);
  const isToggleButtonClicked = !useOutsideAlerter(toggleButtonRef);
  const isOutsideSidePanelClick = useOutsideAlerter(sidePanelRef);

  useEffect(() =>
  {
    if (!isToggleButtonClicked && isOutsideSidePanelClick)
    {
      setOpenSidePanel(false);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isOutsideSidePanelClick]);

  return (
    <div ref = {sidePanelRef} className = {`sidePanel ${(openSidePanel && (isToggleButtonClicked ? true : !isOutsideSidePanelClick)) ? 'openSidePanel' : ''}`}>
      <div className = "backButton">
        <a
          href = "#/"
          onClick = {(event) => 
          {
            event.preventDefault();
            setOpenSidePanel(false);
          }}
        >
          <MdOutlineArrowBack /> Back
        </a>
      </div>

      <div className = "d-flex flex-column justify-content-between" style = {{ height: '65%' }}>
        <div className = "nav_menu">
          <Menu className = "mt-3">
            <SubMenu
              icon = {<FaClipboardList />}
              label = "Deals"
              open = {isDealSubMenuOpen || activeTab === "/" || activeTab.includes("/deals")}
              onOpenChange = {handleDealSubMenuClick}
            >
              {businessUnits.filter(businessUnit => !["Polymers", "SBLC"].includes(businessUnit.value)).map((businessUnit) => (
                <MenuItem
                  key = {businessUnit.id}
                  active = {activeTab === `/deals/${businessUnit.value.toLowerCase()}`}
                  onClick = {() => handleMenuClick(`/deals/${businessUnit.value.toLowerCase()}`, { selectedBusinessUnit: businessUnit })}
                >
                  {businessUnit.value}
                </MenuItem>
              ))}

              <SubMenu
                label = "Polymers"
                open = {isPolymerDealSubMenuOpen || activeTab.includes("deals/polymers") || activeTab.includes("deals/sblc")}
                onOpenChange = {handlePolymerDealSubMenuClick}
              >
                <MenuItem
                  key = {polymerBusinessUnit?.id}
                  active = {activeTab === "/deals/polymers"}
                  onClick = {() => handleMenuClick("/deals/polymers", { selectedBusinessUnit: polymerBusinessUnit })}
                >
                  Polymers
                </MenuItem> 

                <MenuItem
                  key = {sblcBusinessUnit?.id}
                  active = {activeTab === "/deals/sblc"}
                  onClick = {() => handleMenuClick("/deals/sblc", { selectedBusinessUnit: sblcBusinessUnit })}
                >
                  Polymers - SBLC
                </MenuItem> 
              </SubMenu>
            </SubMenu>
            
            <SubMenu
              icon = {<FaTableCells />}
              label = "Entities"
              open = {isEntitySubMenuOpen || activeTab?.includes("entities")}
              onOpenChange = {handleEntitySubMenuClick}
            >
              {currentUser?.role === "Administrator" ? (
                <>
                  <MenuItem
                    active = {activeTab === "/entities/deals"}
                    onClick = {() => handleMenuClick("/entities/deals")}
                  >
                    Deals
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/deal-pfis"}
                    onClick = {() => handleMenuClick("/entities/deal-pfis")}
                  >
                    Deal PFIs
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/banks"}
                    onClick = {() => handleMenuClick("/entities/banks")}
                  >
                    Banks
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/products"}
                    onClick = {() => handleMenuClick("/entities/products")}
                  >
                    Products
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/vendors"}
                    onClick = {() => handleMenuClick("/entities/vendors")}
                  >
                    Vendors
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/trader"}
                    onClick = {() => handleMenuClick("/entities/trader")}
                  >
                    Traders
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/tenor"}
                    onClick = {() => handleMenuClick("/entities/tenor")}
                  >
                    Tenors
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/SBLC"}
                    onClick = {() => handleMenuClick("/entities/SBLC")}
                  >
                    SBLC
                  </MenuItem>

                  <MenuItem
                    active = {activeTab === "/entities/dropdowns"}
                    onClick = {() => handleMenuClick("/entities/dropdowns")}
                  >
                    Dropdowns
                  </MenuItem>
                </>
              ) : (
                <MenuItem
                  active = {activeTab === "/entities/SBLC"}
                  onClick = {() => handleMenuClick("/entities/SBLC")}
                >
                  SBLC
                </MenuItem>
              )}
            </SubMenu>

            <SubMenu
              icon = {<RiPagesLine />}
              label = "Reports"
              open = {isReportSubMenuOpen || activeTab?.includes("reports")}
              onOpenChange = {handleReportSubMenuClick}
            >
              <MenuItem
                active = {activeTab === "/reports/deal-report"}
                onClick = {() => handleMenuClick("/reports/deal-report", { businessUnits: businessUnits, selectedReport: "Deal Report" })}
              >
                Deal Report
              </MenuItem>

              <MenuItem
                active = {activeTab === "/reports/finance-report"}
                onClick = {() => handleMenuClick("/reports/finance-report", { businessUnits: businessUnits, selectedReport: "Finance Report" })}
              >
                Finance Report
              </MenuItem>

              <MenuItem
                active = {activeTab === "/reports/morning-report"}
                onClick = {() => handleMenuClick("/reports/morning-report", { businessUnits: businessUnits, selectedReport: "Morning Report" })}
              >
                Morning Report
              </MenuItem>
            </SubMenu>

            {currentUser?.role === "Administrator" && (
              <>
                <MenuItem
                  icon = {<FaUser />}
                  active = {activeTab === "/create-user"}
                  onClick = {() => handleMenuClick("/create-user", {}, true)}
                  className = "single_dropdown"
                >
                  Create User
                </MenuItem>

                <MenuItem
                  icon = {<IoDocumentsSharp />}
                  active = {activeTab === "/approve-documents"}
                  onClick = {() => handleMenuClick("/approve-documents", { businessUnits: businessUnits }, true)}
                  className = "single_dropdown"
                >
                  Approve Documents
                </MenuItem>
              </>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default SidePanel;