import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { roles } from '../../utility/roles';
import { getPermissions } from '../../lib/accessControl';
import DealReport from './DealReport';
import FinanceReport from './FinanceReport';
import MorningReport from './MorningReport';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';

const Reports = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { pathname } = useLocation();

  /* AUTHORIZATION VARAIBLES */
  const role = useSelector(state => state.user.role);
  const businessUnits = getPermissions(role, null, "businessUnits");

  return (
    <div className = "content-container d-flex flex-column container reports gap-2">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Backdrop
        sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open = {isLoading}
      >
        <CircularProgress color = "inherit" />
      </Backdrop>

      <Typography variant = "h5" gutterBottom className = "page_heading mb-2">
        {pathname?.split('/')?.pop()?.replace(/-/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())} {pathname.includes("morning") ? "(Multi-Filters)" : ""}
      </Typography>

      {pathname?.includes("deal") ? (
        <DealReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      ) : pathname?.includes("finance") ? (
        <FinanceReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      ) : pathname?.includes("morning") && (
        <MorningReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      )}
    </div>
  );
}

export default withAuth(Reports)([
  roles[4], 
  roles[5], 
  roles[6], 
  roles[7], 
  roles[8], 
  roles[10], 
  roles[11], 
  roles[12], 
  roles[13], 
  roles[14], 
  roles[15], 
  roles[16], 
  roles[17], 
  roles[18], 
  roles[19]
]);