import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import dayjs from 'dayjs';
import '../../styles/general.scss';

const BuyingLeg = forwardRef(({ isLoading, selectedTab, selectedPFI, selectedDeal, setIsUpdated, setActiveTab, setIsLoading, setShowAlert, setAlertMessage, setAlertType, logOut }, ref) => 
{
  /* LIST VARIABLES */
  const [banks, setBanks] = useState([]);
  const [incoterms, setIncoterms] = useState([]);
  const [traders, setTraders] = useState([]);
  const [seaPorts, setSeaPorts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [pfis, setPFIs] = useState([]);
  const paymentInstrumentStatus = useMemo(() => ["Pending", "Transmitted"], []);

  /* COPY VARIABLES */
  const [selectedPFIToCopy, setSelectedPFIToCopy] = useState(null);

  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* FORM VARIABLES */
  const dataSchema = {
    Trader_Id: null,
    FZE_Bank_Id: null,
    Supplier_Bank_Id: null,
    LC_Confirming_Bank_Id: null,
    Advise_Through_Bank: "",
    Buying_Currency_Id: null,
    Latest_Shipment_Date: null,
    LC_Expiry_Date: null,
    Port_of_Loading_Id: null,
    Supplier_PFI_No: "",
    Supplier_PFI_Date: null,
    Supplier_CI_No: "",
    Supplier_CI_Date: null,
    PO_No: "",
    PO_Date: null,
    Incoterm_Id: null,
    Remaining_Balance_Payment_Days: null,
    Payment_Instrument_Status: "",
    LC_No: "",
    LC_Issue_Date: null,
    Document_Presentation_Days: null,
    Special_Requirement: ""
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingBuyingLeg, setIsExistingBuyingLeg] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => 
  {
    if (!isFormSavedOrDeleted)
    {
      // Check if form data is different from the initial values
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      setIsUpdated(isFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetBankList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const banks = data?.banks || [];
          setBanks(banks);
        }
      })
      .catch((error) => 
      {        
        console.log("Get Banks Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetTraderList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const traders = data?.traders || [];
          setTraders(traders);
        }
      })
      .catch((error) => 
      {
        console.log("Get Traders Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetIncotermList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const incoterms = data?.incoterms || [];
          setIncoterms(incoterms);
        }
      })
      .catch((error) => 
      {
        console.log("Get Incoterms Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetSeaPortList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const seaports = data?.sea_ports || [];
          setSeaPorts(seaports);
        }
      })
      .catch((error) => 
      {
        console.log("Get Sea Ports Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      axios({
        method: "get",
        url: "/GetCurrencyList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const currencies = data?.currencies || [];
          setCurrencies(currencies);
        }
      })
      .catch((error) => 
      {
        console.log("Get Currencies Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    const fetchBuyingLegData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetBuyingLeg",
        data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const isEmpty = typeof data === "object" ? data?.Deal_PFI_Id ? false : true : true;

          if (!isEmpty && !selectedPFIToCopy) 
          {
            setIsExistingBuyingLeg(true);
          }

          data["PO_Date"] = data["PO_Date"] ? dayjs(data["PO_Date"]).format("MM/DD/YYYY") : data["PO_Date"];
          data["Latest_Shipment_Date"] = data["Latest_Shipment_Date"] ? dayjs(data["Latest_Shipment_Date"]).format("MM/DD/YYYY") : data["Latest_Shipment_Date"];
          data["LC_Expiry_Date"] = data["LC_Expiry_Date"] ? dayjs(data["LC_Expiry_Date"]).format("MM/DD/YYYY") : data["LC_Expiry_Date"];
          data["Supplier_PFI_Date"] = data["Supplier_PFI_Date"] ? dayjs(data["Supplier_PFI_Date"]).format("MM/DD/YYYY") : data["Supplier_PFI_Date"];
          data["Supplier_CI_Date"] = data["Supplier_CI_Date"] ? dayjs(data["Supplier_CI_Date"]).format("MM/DD/YYYY") : data["Supplier_CI_Date"];
          data["LC_Issue_Date"] = data["LC_Issue_Date"] ? dayjs(data["LC_Issue_Date"]).format("MM/DD/YYYY") : data["LC_Issue_Date"];

          setFormData(JSON.parse(JSON.stringify(data)));
          setInitialFormData(data);
        } 
        else if (status === 400)
        {
          setShowAlert(true);
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Buying Leg Data Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchLists();

    if (selectedPFI || selectedPFIToCopy) 
    {
      fetchBuyingLegData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedPFIToCopy]);

  useEffect(() => 
  {
    const fetchPFIList = () => 
    {
      axios({
        method: "post",
        url: "/GetAutoPopulateDealPFIList",
        data: { 
          deal_pfi_id: selectedPFI,
          deal_no: selectedDeal?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          setPFIs(data?.data || []);
        }
      })
      .catch((error) => 
      {
        console.log("Get Shipment Detail Filters Api: ", error);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    if (selectedPFI) 
    {
      fetchPFIList();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI]);
  
  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const handleUpload = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();

    for (let i = 0; i < uploadedFiles.length; i++) 
    {
      const file = uploadedFiles[i];
      const fileObj = {
        DealPFIId: selectedPFIToCopy?.id || selectedPFI,
        fileName: file.name,
        docType: "Buying Leg",
        fileExtension: file.name.split(".").pop()
      };

      fileFormData.append(`file${i}`, JSON.stringify(fileObj));
      fileFormData.append(`file${i}`, file);
    }

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setActiveTab("Selling Leg");
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Buying Leg Documents Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleSelectChange = (newValue) => 
  {
    setSelectedPFIToCopy(newValue);
    setFormData(dataSchema);
  }
  
  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    let updatedFormData = {
      ...formData,
      Deal_PFI_Id: selectedPFI
    };

    const apiUrl = (isExistingBuyingLeg && !selectedPFIToCopy) ? "/UpdateBuyingLeg" : "/AddBuyingLeg";

    updatedFormData = Object.fromEntries(
      Object.entries(updatedFormData).map(([key, value]) => [
        key,
        typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
      ])
    );
  
    await axios({
      method: "post",
      url: apiUrl,
      data: updatedFormData
    })
    .then((response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        if (uploadedFiles.length > 0) 
        {
          handleUpload();
        } 
        else 
        {
          setIsLoading(false);

          if (selectedTab) 
          {
            setActiveTab(selectedTab);
          } 
          else 
          {
            setActiveTab("Selling Leg");
          }
        }

        setIsFormSavedOrDeleted(true);
        setIsUpdated(false);
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add/Update Buying Leg Data Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);

    await axios({
      method: "post",
      url: "/DeleteBuyingLeg",
      data: { Deal_PFI_Id: selectedPFIToCopy?.id || selectedPFI }
    })
    .then(async (response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        await axios({
          method: "post",
          url: "/DeleteDocument",
          data: { fileIds }
        })
        .then((response) => 
        {
          setIsLoading(false);
          const { status } = response;

          if (status === 200) 
          {
            formRef?.current?.reset();

            setFormData((previousFormData) => ({
              ...previousFormData,
              FZE_Bank_Id: null,
              Supplier_Bank_Id: null,
              LC_Confirming_Bank_Id: null,
              Advise_Through_Bank: "",
              Buying_Currency_Id: null,
              Latest_Shipment_Date: null,
              LC_Expiry_Date: null,
              Port_of_Loading_Id: null,
              Supplier_PFI_No: "",
              Supplier_PFI_Date: null,
              Supplier_CI_No: "",
              Supplier_CI_Date: null,
              PO_No: "",
              PO_Date: null,
              Incoterm_Id: null,
              Remaining_Balance_Payment_Days: null,
              Payment_Instrument_Status: "",
              LC_No: "",
              LC_Issue_Date: null,
              Special_Requirement: "",
              Document_Presentation_Days: null
            }));
            setFiles([]);
            setUploadedFiles([]);
            setIsExistingBuyingLeg(false);
            setIsFormSavedOrDeleted(true);
            setAlertMessage("Buying leg data deleted successfully.");
            setAlertType("success");
            setShowAlert(true);
            setIsUpdated(false);
          } 
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Buying Leg Documents Api: ", error);
          setIsLoading(false);
          
          if (error?.response?.status === 403) 
          {
            logOut();
          }
          else
          {
            setAlertMessage(
              error?.response?.status === 429
                ? "Request limit exceeded. Please try again later."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
          }
        });
      } 
      else if (status === 400)
      {
        setShowAlert(true);
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Delete Buying Leg Data Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
  }

  return (
    <div>
      <div className = "d-flex justify-content-between align-items-center">
        <Typography variant = "h5" gutterBottom className = "page_heading m-0">
          Buying Leg Information
        </Typography>
        
        <div className = "copy-pfi autocomplete">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            filterOn = "Select PFI to Auto Populate"
            options = {pfis}
            selectedOptions = {selectedPFIToCopy}
            handleSelectChange = {(filterOn, newValue) => handleSelectChange(newValue)}
          />
        </div>
      </div>

      <div className = "input-fields my-2 p-2">
        <form
          ref = {formRef}
          className = "progress_form_con mt-1"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "PO No."
                  id = "PO_No"
                  value = {formData.PO_No || ""}
                  onChange = {(event) => handleChange("PO_No", event.target.value)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PO Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.PO_Date)}
                    onChange = {(newDate) => handleChange("PO_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PO_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Supplier PFI No."
                  id = "Supplier_PFI_No"
                  value = {formData.Supplier_PFI_No || ""}
                  onChange = {(event) => handleChange("Supplier_PFI_No", event.target.value)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier PFI Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Supplier_PFI_Date)}
                    onChange = {(newDate) => handleChange("Supplier_PFI_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Supplier_PFI_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "FZE Bank"
                  options = {banks}
                  selectedOptions = {formData?.FZE_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("FZE_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Supplier Bank"
                  options = {banks}
                  selectedOptions = {formData?.Supplier_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Supplier_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Latest Date of Shipment"
                    className = "date-picker w-full"
                    disableMaskedInput
                    value = {convertToDayJsObject(formData?.Latest_Shipment_Date)}
                    onChange = {(newDate) => handleChange("Latest_Shipment_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Latest_Shipment_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Confirming Bank"
                  options = {banks}
                  selectedOptions = {formData?.LC_Confirming_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("LC_Confirming_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Buying Currency"
                  options = {currencies}
                  selectedOptions = {formData?.Buying_Currency_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Buying_Currency_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Incoterm"
                  options = {incoterms}
                  selectedOptions = {formData?.Incoterm_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Incoterm_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  filterOn = "LC Status"
                  options = {paymentInstrumentStatus}
                  selectedOptions = {formData?.Payment_Instrument_Status || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Payment_Instrument_Status", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "LC No."
                  id = "LC_No"
                  value = {formData?.LC_No || ""}
                  onChange = {(event) => handleChange("LC_No", event.target.value)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "LC Issue Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.LC_Issue_Date)}
                    onChange = {(newDate) => handleChange("LC_Issue_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("LC_Issue_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Document Presentation Days"
                  type = "number"
                  id = "Document_Presentation_Days"
                  value = {formData?.Document_Presentation_Days || ""}
                  onChange = {(event) => 
                  {
                    const value = event.target.value;

                    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 100)) 
                    {
                      handleChange("Document_Presentation_Days", value);
                    }
                  }}
                  InputProps = {{ inputProps: { min: 1, max: 100 } }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Supplier CI No."
                  id = "Supplier_CI_No"
                  value = {formData.Supplier_CI_No || ""}
                  onChange = {(event) => handleChange("Supplier_CI_No", event.target.value)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier CI Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Supplier_CI_Date)}
                    onChange = {(newDate) => handleChange("Supplier_CI_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Supplier_CI_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Trader Name"
                  options = {traders}
                  selectedOptions = {formData?.Trader_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Trader_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Advise Through Bank"
                  id = "Advise_Through_Bank"
                  value = {formData.Advise_Through_Bank || ""}
                  onChange = {(event) => handleChange("Advise_Through_Bank", event.target.value)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "LC Expiry Date"
                    disableMaskedInput
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.LC_Expiry_Date)}
                    onChange = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {true}
                  filterOn = "Port of Loading"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_of_Loading_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_of_Loading_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Remaining Balance Payment Days"
                  type = "number"
                  id = "Remaining_Balance_Payment_Days"
                  value = {formData?.Remaining_Balance_Payment_Days || ""}
                  onChange = {(event) => handleChange("Remaining_Balance_Payment_Days", event.target.value)}
                  InputProps = {{ inputProps: { min: 0, max: 2147483647 } }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 4 }} className = "form-text-field">
                <TextField
                  label = "Special Requirement"
                  id = "Special_Requirement"
                  multiline
                  rows = {3}
                  value = {formData?.Special_Requirement || ""}
                  onChange = {(event) => handleChange("Special_Requirement", event.target.value)}
                />
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end submit-buttons">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingBuyingLeg ? "Update" : "Save"}
        </button>

        {isExistingBuyingLeg && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete the form details? 
            This process cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default BuyingLeg;