import { useCallback, useEffect, useState } from 'react';
import EntityDataTable from '../../components/EntityDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';
import axios from 'axios';

const Vendor = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  /* DATATABLE ROW VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);

  /* LIST VARIABLES */
  const [dropdowns, setDropdowns] = useState({ KYC_Status: ["In-Progress", "Completed"] });

  /* EDITABLE ROW VARIABLES */
  const [isEditableRecordLoading, setIsEditableRecordLoading] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "get",
        url: "/GetBusinessUnitList"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const businessUnits = data?.business_units || [];
          setDropdowns((previousDropdowns) => ({
            ...previousDropdowns,
            Business_Unit: businessUnits
          }));
        }
      })
      .catch((error) => 
      {
        console.log("Get Business Units Api: ", error);

        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/GetVendors"
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const vendorData = data?.vendor_data || [];
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = vendorData;
          const columnData = columns.filter((column) => !(column === "id" || column === "isDeleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        } 
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Update Vendor Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });

      await axios({
        method: "get",
        url: "/GetDocuments"
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const vendorFiles = data?.map((file) => (
          {
            file: {
              name: file.File_Name,
              id: file.File_Id,
              DealPFIId: file.Deal_PFI_Id,
              location: file.File_Location
            },
            docName: file.Doc_Type
          })) || [];

          setFiles(vendorFiles);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Vendor Documents Api: ", error);
        setIsLoading(false);
        
        if (error?.response?.status === 403) 
        {
          logOut();
        }
        else
        {
          setAlertMessage(
            error?.response?.status === 429
              ? "Request limit exceeded. Please try again later."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }
      });
    }

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleDisplayData]);

  const handleFileUpload = async (id) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();

    for (let i = 0; i < uploadedFiles.length; i++) 
    {
      const file = uploadedFiles[i];
      const fileObj = {
        DealPFIId: id,
        TrancheID: "",
        fileName: file.name,
        docType: "Vendor",
        fileExtension: file.name.split(".").pop()
      };

      fileFormData.append(`file${i}`, JSON.stringify(fileObj));
      fileFormData.append(`file${i}`, file);
    }

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Vendor Documents Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleUpdateRecord = async (editedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/UpdateVendor",
      data: editedRecord
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        if (uploadedFiles.length > 0) 
        {
          handleFileUpload(editedRecord.id);
        } 
        else 
        {
          setToggleDisplayData(!toggleDisplayData);
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Update Vendor Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleAddRecord = async (addedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/AddVendor",
      data: addedRecord
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        if (uploadedFiles.length > 0) 
        {
          handleFileUpload(data?.id);
        } 
        else 
        {
          setToggleDisplayData(!toggleDisplayData);
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add Vendor Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleRecordStatusChangeWrapper = async (id, status) => 
  {
    setRowId(id);
    setRowStatus(status);

    if (status === 1) 
    {
      setShowDisableModal(true);
    } 
    else 
    {
      handleRecordStatusChange(id, status);
    }
  }

  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/SetVendorStatus",
      data: {
        id: rowId || id,
        status: rowStatus || status
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Vendor Status Api: ", error);
      setIsLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus]);

  const handleGetRecord = async (rowId) => 
  {
    setRecordToEdit(null);
    setIsEditableRecordLoading(true);

    await axios({
      method: "post",
      url: "/GetVendorRecord",
      data: {
        vendor_id: rowId
      }
    })
    .then((response) => 
    {
      setIsEditableRecordLoading(false);
      const { status, data } = response;
    
      if (status === 200) 
      {
        setRecordToEdit(data);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Get Vendor Record Api: ", error);
      setIsEditableRecordLoading(false);
      
      if (error?.response?.status === 403) 
      {
        logOut();
      }
      else
      {
        setAlertMessage(
          error?.response?.status === 429
            ? "Request limit exceeded. Please try again later."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
      }
    });
  }

  const handleCloseModal = () => 
  {
    setShowDisableModal(false);
  }

  return (
    <div className = "content_container d-flex flex-column container entities">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Typography variant = "h5" gutterBottom className = "page_heading m-0">
        Vendors
      </Typography>

      <div>
        <Backdrop
          sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        />

        <EntityDataTable
          title = "Vendor"
          isLoading = {isLoading}
          columnData = {columnData}
          columnDataTypes = {columnDataTypes}
          columnWidths = {columnWidths}
          doesContainDropdowns = {true}
          dropdowns = {dropdowns}
          rows = {rows}
          files = {files}
          uploadedFiles = {uploadedFiles}
          isEditable = {true}
          isEditableRecordLoading = {isEditableRecordLoading}
          recordToEdit = {recordToEdit}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          setFiles = {setFiles}
          setUploadedFiles = {setUploadedFiles}
          setRecordToEdit = {setRecordToEdit}
          handleUpdateRecord = {handleUpdateRecord}
          handleAddRecord = {handleAddRecord}
          handleRecordStatusChange = {handleRecordStatusChangeWrapper}
          handleGetRecord = {handleGetRecord}
        />
      </div>

      <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
        <Modal.Header closeButton = {true}>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Disabling the vendor will remove it from the list of available
            vendors.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant = "contained"
            size = "small"
            onClick = {handleRecordStatusChange}
          >
            Disable
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default withAuth(Vendor)([roles.Administrator]);