import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { roles } from '../../utility/roles';
import { getPermissions } from '../../lib/accessControl';
import useTab from '../../hooks/useTab';
import Alert from '../../components/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DealNo from './DealNo';
import DealHeader from './DealHeader';
import BuyingLeg from './BuyingLeg';
import SellingLeg from './SellingLeg';
import Documentation from './Documentation';
import ShipmentDetails from './ShipmentDetails';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import withAuth from '../../lib/withAuth';
import '../../styles/general.scss';

const BusinessUnit = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { pathname } = useLocation();

  /* AUTHORIZATION VARIABLES */
  const role = useSelector(state => state.user.role);
  const { isWritable = false } = getPermissions(role, pathname);
  
  /* TAB VARIABLES */
  const { activeTab, selectedDeal, selectedPFI, setActiveTab, setSelectedDeal, setSelectedPFI } = useTab();
  const [selectedTab, setSelectedTab] = useState(null);

  /* DATA VARIABLES */
  const [isUpdated, setIsUpdated] = useState(false);

  /* DIALOG VARIABLES */
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  /* COMPONENT REFERENCE VARIABLES */
  const dealHeaderRef = useRef(null);
  const buyingLegRef = useRef(null);
  const sellingLegRef = useRef(null);
  const shipmentDetailsRef = useRef(null);
  const documentationRef = useRef(null);

  const handleChangeTab = (tab) =>
  {
    if (tab === "Deal No.") 
    {
      setSelectedDeal(null);
      setSelectedPFI(null);
      setActiveTab(tab);
    } 
    else if (selectedDeal && tab === "PFI")
    {
      setSelectedPFI(null);
      setActiveTab(tab);
    }
    else if (selectedDeal && selectedPFI) 
    {
      setActiveTab(tab);
    }

    setIsUpdated(false);
    setSelectedTab(null);
  }

  const handleTabChange = (tab) => 
  {
    if (isUpdated) 
    {
      setSelectedTab(tab);
      setShowConfirmationDialog(true);
    } 
    else 
    {
      handleChangeTab(tab);
    }
  }

  const handleSaveChanges = () => 
  {
    switch (activeTab) 
    {
      case "Deal Header":
        dealHeaderRef?.current?.handleSubmit();
        break;
      case "Buying Leg":
        buyingLegRef?.current?.handleSubmit();
        break;
      case "Selling Leg":
        sellingLegRef?.current?.handleSubmit();
        break;
      case "Shipment Details":
        shipmentDetailsRef?.current?.handleSubmit();
        break;
      case "Documentation":
        documentationRef?.current?.handleSubmit();
        break;
      default:
        break;
    }

    // handleChangeTab(selectedTab);
    setShowConfirmationDialog(false);
  }

  const handleDiscardChanges = () => 
  {
    handleChangeTab(selectedTab);
    setShowConfirmationDialog(false);
  }

  const handleCloseConfirmationDialog = () =>
  {
    setShowConfirmationDialog(false);
  }

  return (
    <div className = "container">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <div className = "row justify-content-center mt-1">
        {selectedDeal && (
          <div className = "d-flex align-items-center justify-content-center mb-1">
            <h4 className = "text-center m-0">
              Deal No.:{" "}
              <span className = "font-normal">{selectedDeal.value}{selectedPFI ? selectedPFI.value : ""}</span>
            </h4>
          </div>
        )}

        <div className = "col-md-12 mb-2 text-center">
          <div className = "btn-group tabs_bar" role = "group">
            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Deal No." ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Deal No.")}
              disabled = {isLoading && activeTab !== "Deal No."}
            >
              Deal No.
            </button>

            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Deal Header" ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Deal Header")}
              disabled = {isLoading && activeTab !== "Deal Header"}
            >
              Deal Header
            </button>

            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Buying Leg" ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Buying Leg")}
              disabled = {isLoading && activeTab !== "Buying Leg"}
            >
              Buying Leg
            </button>

            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Selling Leg" ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Selling Leg")}
              disabled = {isLoading && activeTab !== "Selling Leg"}
            >
              Selling Leg
            </button>

            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Shipment Details" ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Shipment Details")}
              disabled = {isLoading && activeTab !== "Shipment Details"}
            >
              Shipment Details
            </button>

            <button
              type = "button"
              className = {`switch_button btn ${activeTab === "Documentation" ? "button_secondary" : "btn-light"}`}
              onClick = {() => handleTabChange("Documentation")}
              disabled = {isLoading && activeTab !== "Documentation"}
            >
              Documentation
            </button>
          </div>
        </div>
      </div>

      <div className = {`content-container d-flex flex-column mt-0 deals ${selectedDeal ? "deal-selected" : ""}`}>
        <Backdrop
          sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        >
          <CircularProgress color = "inherit" />
        </Backdrop>

        <Dialog open = {showConfirmationDialog} onClose = {handleCloseConfirmationDialog}>
          <DialogContent>
            <DialogContentText className = "text-center">
              Save changes before proceeding?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <button
              type = "button"
              variant = "contained"
              className = "btn btn_secondary custom_btn"
              onClick = {handleSaveChanges}
            >
              Save
            </button>

            <button
              type = "button"
              variant = "contained"
              className = "btn btn-danger custom_btn"
              onClick = {handleDiscardChanges}
            >
              Discard
            </button>
          </DialogActions>
        </Dialog>

        {(activeTab === "Deal No.") ? (
          <DealNo
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedTab = {selectedTab}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setSelectedDeal = {setSelectedDeal}
            setSelectedPFI = {setSelectedPFI}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        ) : (activeTab === "Deal Header") ? (
          <DealHeader
            ref = {dealHeaderRef}
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedTab = {selectedTab}
            selectedDeal = {selectedDeal}
            selectedPFI = {selectedPFI?.id}
            setSelectedPFI = {setSelectedPFI}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        ) :(activeTab === "Buying Leg") ? (
          <BuyingLeg
            ref = {buyingLegRef}
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedTab = {selectedTab}
            selectedDeal = {selectedDeal}
            selectedPFI = {selectedPFI?.id}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        ) : (activeTab === "Selling Leg") ? (
          <SellingLeg
            ref = {sellingLegRef}
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedTab = {selectedTab}
            selectedDeal = {selectedDeal}
            selectedPFI = {selectedPFI?.id}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        ) : (activeTab === "Shipment Details") ? (
          <ShipmentDetails
            ref = {shipmentDetailsRef}
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedTab = {selectedTab}
            selectedDeal = {selectedDeal}
            selectedPFI = {selectedPFI?.id}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        ) : (activeTab === "Documentation") && (
          <Documentation
            ref = {documentationRef}
            isWritable = {isWritable}
            isLoading = {isLoading}
            selectedDeal = {selectedDeal}
            selectedPFI = {selectedPFI?.id}
            setIsUpdated = {setIsUpdated}
            setActiveTab = {setActiveTab}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            logOut = {logOut}
          />
        )}
      </div>
    </div>
  );
}

export default withAuth(BusinessUnit)([
  roles[2], 
  roles[3], 
  roles[4], 
  roles[5], 
  roles[6], 
  roles[7], 
  roles[8], 
  roles[10], 
  roles[11], 
  roles[12], 
  roles[13], 
  roles[14], 
  roles[15], 
  roles[16], 
  roles[17], 
  roles[18], 
  roles[19]
]);