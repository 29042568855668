import { routes } from './routes';
import roles from '../utility/roles';

const routeConfig = {
    login: {
        default: routes.login,
        [routes.login]: routes.login,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.resetPassword]: routes.resetPassword,
        [routes.changePassword]: routes.changePassword
    },
    [roles.Administrator]: {
        default: routes.dealsFertilizers,
        [routes.createUser]: routes.createUser,
        [routes.editProfile]: routes.editProfile,
        [routes.dealsFertilizers]: routes.dealsFertilizers,
        [routes.dealsFoods]: routes.dealsFoods,
        [routes.dealsMiscellaneous]: routes.dealsMiscellaneous,
        [routes.dealsFeedstocks]: routes.dealsFeedstocks,
        [routes.dealsPolymers]: routes.dealsPolymers,
        [routes.dealsSblc]: routes.dealsSblc,
        [routes.entitiesBanks]: routes.entitiesBanks,
        [routes.entitiesTrader]: routes.entitiesTrader,
        [routes.entitiesTenor]: routes.entitiesTenor,
        [routes.entitiesDeals]: routes.entitiesDeals,
        [routes.entitiesDealPfis]: routes.entitiesDealPfis,
        [routes.entitiesDropdowns]: routes.entitiesDropdowns,
        [routes.entitiesProducts]: routes.entitiesProducts,
        [routes.entitiesSblc]: routes.entitiesSblc,
        [routes.entitiesVendors]: routes.entitiesVendors,
        [routes.reportsDealReport]: routes.reportsDealReport,
        [routes.reportsFinanceReport]: routes.reportsFinanceReport,
        [routes.reportsMorningReport]: routes.reportsMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles.User]: {
        default: routes.dealsFertilizers,
        [routes.editProfile]: routes.editProfile,
        [routes.dealsFertilizers]: routes.dealsFertilizers,
        [routes.dealsFoods]: routes.dealsFoods,
        [routes.dealsMiscellaneous]: routes.dealsMiscellaneous,
        [routes.dealsFeedstocks]: routes.dealsFeedstocks,
        [routes.dealsPolymers]: routes.dealsPolymers,
        [routes.dealsSblc]: routes.dealsSblc,
        [routes.entitiesSblc]: routes.entitiesSblc,
        [routes.reportsDealReport]: routes.reportsDealReport,
        [routes.reportsFinanceReport]: routes.reportsFinanceReport,
        [routes.reportsMorningReport]: routes.reportsMorningReport,
        [routes.pageNotFound]: routes.pageNotFound
    }
};

export default routeConfig;