import { useContext, useEffect, useState } from 'react';
import { LogoutModalContext } from '../contexts/LogoutModalContext';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';

const LogoutModal = () => 
{
    const { 
        isLoading, 
        showLogoutModal, 
        isSessionExpired, 
        handleLogout, 
        handleExtendSession 
    } = useContext(LogoutModalContext);

    /* MODAL VARIABLES */
    const [countdown, setCountdown] = useState(15);
    const modalMessage = isSessionExpired
        ? "Your session has timed out. To continue working, please log in again."
        : `Your session is about to expire in ${countdown} seconds. Do you want to extend your session?`;

    useEffect(() => 
    {
        if (showLogoutModal) 
        {
            setCountdown(15);

            const timer = setInterval(() => 
            {
                setCountdown((previousCountdown) =>
                {
                    if (previousCountdown === 1) 
                    {
                        clearInterval(timer);
                        handleLogout();
                    }
                    
                    return previousCountdown - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [showLogoutModal]);

    return (
        <Modal show = {showLogoutModal} onHide = {handleLogout} centered>
            <Modal.Header closeButton>
                <Modal.Title>{isSessionExpired ? "Session Expired" : "Session Timed Out"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>{modalMessage}</span>
            </Modal.Body>
            <Modal.Footer className = {`${isSessionExpired ? '' : 'gap-2'}`}>
                {isSessionExpired ? (
                    <Button color = "primary" variant = "contained" size = "small" onClick = {handleLogout}>
                        {isLoading ? <div className = "spinner-border text-info" role = "status" /> : 'Ok'}
                    </Button>
                ) : (
                    <>
                        <Button 
                            color = "primary" 
                            variant = "contained" 
                            size = "small" 
                            onClick = {() => 
                            {
                                handleExtendSession();
                                setCountdown(15);
                            }}
                        >
                            Extend Session
                        </Button>
                        <Button color = "primary" variant = "outlined" size = "small" onClick = {handleLogout}>
                            {isLoading ? <div className = "spinner-border text-info" role = "status" /> : 'Logout'}
                        </Button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default LogoutModal;