import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getPermissions } from '../lib/accessControl';
import SideBar from './SideBar';

const SidebarNavigation = ({ currentUser, logOut = () => {}, component }) => 
{
    const navigate = useNavigate();
    
    /* AUTHORIZATION VARAIBLES */
    const role = useSelector(state => state.user.role);
    const businessUnits = getPermissions(role, null, "businessUnits");

    useEffect(() =>
    {
        const isLoggedIn = JSON.parse(localStorage.getItem("isLoggedIn")) || false;

        if (businessUnits.length > 0 && !isLoggedIn)
        {
            const firstUnit = businessUnits[0];

            localStorage.setItem("isLoggedIn", JSON.stringify(true));
            navigate(`/deals/${firstUnit?.value?.toLowerCase()}`, {
                state: { selectedBusinessUnit: firstUnit }
            });
        }
    
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [businessUnits]);
    
    return (
        <SideBar 
            currentUser = {currentUser} 
            component = {component} 
            businessUnits = {businessUnits}
            logOut = {logOut} 
        />
    );
}

export default SidebarNavigation;