import { useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { LogoutModalContext } from '../contexts/LogoutModalContext';
import { TabProvider } from '../contexts/TabContext';
import { BusinessUnitProvider } from '../contexts/BusinessUnitContext';
import { PermissionsProvider } from '../contexts/PermissionsContext';
import { getDefaultPage } from '../lib/routeControl';
import useUser from '../hooks/useUser';
import routes from '../utility/routes';
import ProtectedRoute from './ProtectedRoute';
import SidebarNavigation from '../navigation/SidebarNavigation';
import Login from '../pages/authentication/Login';
import SetNewPassword from '../pages/authentication/SetNewPassword';
import ResetPassword from '../pages/authentication/ResetPassword';
import ChangePassword from '../pages/authentication/ChangePassword';
import CreateUser from '../pages/authentication/CreateUser';
import EditProfile from '../pages/authentication/EditProfile';
import BusinessUnit from '../pages/deals/BusinessUnit';
import Bank from '../pages/entities/Bank';
import Deal from '../pages/entities/Deal';
import DealPFI from '../pages/entities/DealPFI';
import Dropdowns from '../pages/entities/Dropdowns';
import FileType from '../pages/entities/FileType';
import Product from '../pages/entities/Product';
import SBLC from '../pages/entities/SBLC/SBLC';
import Tenor from '../pages/entities/Tenor';
import Trader from '../pages/entities/Trader';
import Vendor from '../pages/entities/Vendor';
import Reports from '../pages/reports/Reports';
import ApproveDocuments from '../pages/documents/ApproveDocuments';
import PageNotAccessible from '../PageNotAccessible';
import PageNotFound from '../PageNotFound';

const RouterLinks = () => 
{
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();
    const { handleLogout } = useContext(LogoutModalContext);

    useEffect(() =>
    {
        if (currentUser?.id && localStorage.getItem('isLoggedIn') && pathname === "/")
        {
            const defaultPage = getDefaultPage(currentUser?.role);
            navigate(defaultPage);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [pathname, currentUser]);

    const isValidPath = () => 
    {
        return Object.values(routes).some(route => route === pathname);
    }

    return (
        <TabProvider>
            <BusinessUnitProvider logOut = {handleLogout}>
                <PermissionsProvider role = {currentUser?.role}>
                    <Routes>
                        <Route
                            path = "/login"
                            element = {<Login />}
                        />

                        <Route
                            path = "/set-new-password"
                            element = {
                                <SetNewPassword 
                                    currentUser = {currentUser}
                                    logOut = {handleLogout} 
                                />
                            }
                        />

                        <Route
                            path = "/reset-password"
                            element = {<ResetPassword currentUser = {currentUser} />}
                        />

                        <Route
                            path = "/change-password/user/:id"
                            element = {<ChangePassword currentUser = {currentUser} />}
                        />

                        <Route element = {<ProtectedRoute isAllowed = {!!currentUser} />}>
                            <Route
                                path = "/"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/fertilizers"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/foods"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/miscellaneous"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/petrochemicals/feedstocks"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/polymers"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/deals/sblc"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<BusinessUnit logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/banks"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Bank logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/deals"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Deal logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/deal-pfis"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<DealPFI logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/dropdowns"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Dropdowns logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/file-types"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<FileType logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/products"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Product logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/sblc"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<SBLC logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/tenors"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Tenor logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/traders"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Trader logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/entities/vendors"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Vendor logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/reports/deal-report"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Reports logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/reports/finance-report"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Reports logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/reports/morning-report"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<Reports logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/approve-documents"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<ApproveDocuments logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/create-user"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<CreateUser logOut = {handleLogout} />}
                                    />
                                }
                            />

                            <Route
                                path = "/edit-profile"
                                element = {
                                    <SidebarNavigation
                                        currentUser = {currentUser}
                                        logOut = {handleLogout}
                                        component = {<EditProfile logOut = {handleLogout} />}
                                    />
                                }
                            />
                        </Route>

                        <Route
                            path = "*"
                            element = {currentUser 
                                ? isValidPath() 
                                    ? <PageNotAccessible /> 
                                    : <PageNotFound /> 
                                : <Navigate to = "/login" replace = {true} />
                            }
                        />
                    </Routes>
                </PermissionsProvider>
            </BusinessUnitProvider>
        </TabProvider>
    );
}

export default RouterLinks;