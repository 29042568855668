import { useMemo, useRef } from 'react';
import { ImCross } from 'react-icons/im';
import Grid  from '@mui/material/Grid2';
import VirtualizedAutocomplete from './Autocomplete';
import axios from 'axios';

const FileUploader = ({ isMultiple = true, files = [], uploadedFiles = [], allowFileTypeSelection = false, buyingFileTypes = [], sellingFileTypes = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setDeletedRowFileIds = () => {}, setFormData = () => {} }) => 
{
    /* FILE VARIABLES */
    const maxFileSize = 5 * 1024 * 1024;
    const fileInputRef = useRef(null);
    const fileTypes = useMemo(() => ["pdf", "doc", "docx", "xlsx", "csv", "jpg", "jpeg", "png"], []);

    const handleChange = (event) => 
    {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const newFiles = Object.values(event.target.files) || [];
        const invalidFiles = newFiles.filter(file => file.size > maxFileSize);
        const isValidFileType = newFiles.some(file => fileTypes.includes(file.name.split(".").pop()));
        const isMultipleFiles = files.length === 1 || uploadedFiles.length === 1;

        if (invalidFiles.length > 0)
        {
            setAlertMessage(invalidFiles.length ? `The ${isMultiple ? "file exceeds" : "following files exceed"} the 5MB limit: ${invalidFiles.map(file => file.name).join(", ")}.` : "");
            setAlertType("error");
            setShowAlert(true);
        }
        else if (!isMultiple && isMultipleFiles) 
        {
            setAlertMessage("Please upload one file only.");
            setAlertType("error");
            setShowAlert(true);
        } 
        else if (!isValidFileType) 
        {
            setAlertMessage(`Please upload files with the following extensions: ${fileTypes.join(", ")}.`);
            setAlertType("error");
            setShowAlert(true);
        } 
        else 
        {
            const uniqueFiles = new Set(uploadedFiles.map(file => file.name + file.size));
            const newUploadedFiles = newFiles.filter(file => !uniqueFiles.has(file.name + file.size));

            if (isMultiple === false) 
            {
                setFormData((previousFormData) => ({
                    ...previousFormData,
                    "Trade_License_Document": newUploadedFiles[0]?.name || ""
                }));
            }

            setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
        }

        if (fileInputRef?.current?.value) 
        {
            fileInputRef.current.value = null;
        }
    }

    const handleDeleteExistingFile = async (index, id) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/DeleteDocument",
            data: { fileIds: [id] }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;

            if (status === 200) 
            {
                const updatedFiles = [...files];
                updatedFiles.splice(index, 1);

                setFiles(updatedFiles);
                setDeletedRowFileIds((previousDeletedFileIds) => [...previousDeletedFileIds, id]);
                setAlertMessage("File Deleted Successfully.");
                setAlertType("success");
                setShowAlert(true);
            } 
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Get Documents Api: ", error);
            setIsLoading(false);
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
        });
    }

    const handleDeleteNewFile = (index) => 
    {
        const newUploadedFiles = [...uploadedFiles];
    
        newUploadedFiles.splice(index, 1);
        setUploadedFiles(newUploadedFiles);
    }

    const handleTabTypeChange = (index, value) =>
    {
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles[index].tabType = value;

        if (!value)
        {
            updatedUploadedFiles[index].docType = null;
        }

        setUploadedFiles(updatedUploadedFiles);
    }

    const handleUploadedFileChange = (index, value) => 
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].docType = value;
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleViewFile = async (event, file) =>
    {
        event.preventDefault();

        const { location: fileBlobName } = file.file;

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/AccessDocument",
            data: { 
                FileBlobName: fileBlobName,
                view_document: true
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200) 
            {
                window.open(data, "_blank", "noopener, noreferrer");
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("info");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Download Document Api: ", error);
            setIsLoading(false);
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
        });
    }

    return (
        <div className = "upload-container my-3 w-100">
            <Grid container spacing = {2} className = "w-100">
                <Grid size = {{ xs: 3 }} className = "pt-0">
                    <div className = "form-group">
                        <div className = "form-control">
                            <input
                                ref = {fileInputRef}
                                className = "form-control"
                                type = "file"
                                name = "documents"
                                multiple = {isMultiple}
                                placeholder = "Choose File"
                                accept = {fileTypes.map(type => `.${type}`).join(", ")}
                                onChange = {handleChange}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            {files?.map((file, index) => (
                <Grid key = {index} container spacing = {2} className = "mt-2">
                    <Grid size = {{ xs: 2 }}>
                        <a href = "#/" onClick = {(event) => handleViewFile(event, file)}>
                            <span className = "uploaded-file cursor-pointer">
                                {file.file.name}
                            </span>
                        </a>
                    </Grid>
                    
                    {allowFileTypeSelection && (
                        <>
                            <Grid size = {{ xs: 2 }}>
                                <span>{file.file.tabType}</span>
                            </Grid>

                            <Grid size = {{ xs: 2 }}>
                                <span>{file.file.fileType}</span>
                            </Grid>
                        </>
                    )}

                    <Grid size = {{ xs: 1 }}>
                        <ImCross
                            className = "delete-file cursor-pointer"
                            onClick = {() => handleDeleteExistingFile(index, file.file.id)}
                        />
                    </Grid>
                </Grid>
            ))}

            {uploadedFiles?.map((file, index) => (
                <Grid key = {index} container spacing = {2} className = "mt-2 align-items-center">
                    <Grid size = {{ xs: 2 }}>
                        <span className = "uploaded-file cursor-pointer">
                            {file.name}
                        </span>
                    </Grid>

                    {allowFileTypeSelection && (
                        <>
                            <Grid size = {{ xs: 2 }}>
                                <VirtualizedAutocomplete
                                    isMultiple = {false}
                                    isObject = {false}
                                    isRequired = {true}
                                    filterOn = "Tab Type"
                                    options = {["Buying Leg", "Selling Leg"]}
                                    selectedOptions = {file.tabType || null}
                                    handleSelectChange = {(filterOn, newValue) => handleTabTypeChange(index, newValue)}
                                />
                            </Grid>

                            <Grid size = {{ xs: 2 }}>
                                <VirtualizedAutocomplete
                                    isMultiple = {false}
                                    isObject = {false}
                                    isDisabled = {!file.tabType}
                                    isRequired = {true}
                                    filterOn = "File Type"
                                    options = {file.tabType === "Buying Leg" ? buyingFileTypes : file.tabType === "Selling Leg" ? sellingFileTypes : []}
                                    selectedOptions = {file.docType || null}
                                    handleSelectChange = {(filterOn, newValue) => handleUploadedFileChange(index, newValue)}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid size = {{ xs: 2 }}>
                        <ImCross
                            className = "delete-file cursor-pointer"
                            onClick = {() => handleDeleteNewFile(index)}
                        />
                    </Grid>
                </Grid>
            ))}
        </div>
    );
}

export default FileUploader;