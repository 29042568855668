import { useMemo, useRef } from 'react';
import Grid  from '@mui/material/Grid2';
import VirtualizedAutocomplete from './Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const FileUploader = ({ isWritable = false, isLoading = false, isMultiple = true, pathname = null, files = [], uploadedFiles = [], allowFileTypeSelection = false, buyingLegFileTypes = [], sellingLegFileTypes = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setDeletedRowFileIds = () => {}, setFormData = () => {}, triggerSessionExpire = () => {}, logOut = () => {} }) => 
{
    /* FILE VARIABLES */
    const maxFileSize = 5 * 1024 * 1024;
    const fileInputRef = useRef(null);
    const fileTypes = useMemo(() => ["pdf", "doc", "docx", "xlsx", "csv", "jpg", "jpeg", "png"], []);

    const handleChange = (event) => 
    {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const newFiles = Object.values(event.target.files) || [];
        const invalidFiles = newFiles.filter(file => file.size > maxFileSize);
        const isValidFileType = newFiles.every(file => fileTypes.includes(file.name.split(".").pop()));
        const isMultipleFiles = files.length === 1 || uploadedFiles.length === 1;

        if (invalidFiles.length > 0)
        {
            setAlertMessage(invalidFiles.length ? `The ${isMultiple ? "file exceeds" : "following files exceed"} the 5MB limit: ${invalidFiles.map(file => file.name).join(", ")}.` : "");
            setAlertType("error");
            setShowAlert(true);
        }
        else if (!isMultiple && isMultipleFiles) 
        {
            setAlertMessage("Please upload one file only.");
            setAlertType("error");
            setShowAlert(true);
        } 
        else if (!isValidFileType) 
        {
            setAlertMessage(`Please upload files with the following extensions: ${fileTypes.join(", ")}.`);
            setAlertType("error");
            setShowAlert(true);
        } 
        else 
        {
            const uniqueFiles = new Set(uploadedFiles.map(file => file.name + file.size));
            const newUploadedFiles = newFiles.filter(file => !uniqueFiles.has(file.name + file.size));

            if (isMultiple === false) 
            {
                setFormData((previousFormData) => ({
                    ...previousFormData,
                    "Trade_License_Document": newUploadedFiles[0]?.name || ""
                }));
            }

            setUploadedFiles([...uploadedFiles, ...newUploadedFiles]);
        }

        if (fileInputRef?.current?.value) 
        {
            fileInputRef.current.value = null;
        }
    }

    const handleDeleteExistingFile = async (index, id) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/DeleteDocument",
            data: { 
                pathname: pathname,
                fileIds: [id] 
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;

            if (status === 200) 
            {
                const updatedFiles = [...files];
                updatedFiles.splice(index, 1);

                setFiles(updatedFiles);
                setDeletedRowFileIds((previousDeletedFileIds) => [...previousDeletedFileIds, id]);
                setAlertMessage("File Deleted Successfully.");
                setAlertType("success");
                setShowAlert(true);
            } 
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Get Documents Api: ", error);
            setIsLoading(false);

            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleDeleteNewFile = (index) => 
    {
        const newUploadedFiles = [...uploadedFiles];
    
        newUploadedFiles.splice(index, 1);
        setUploadedFiles(newUploadedFiles);
    }

    const handleTabTypeChange = (index, value) =>
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].tabType = value;
        updatedUploadedFiles[index].docType = null;
        
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleSetMarkForApproval = (index, value) =>
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].markForApproval = value;
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleUploadedFileChange = (index, value) => 
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].docType = value;
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleViewFile = async (event, file) =>
    {
        event.preventDefault();

        const { location: fileBlobName } = file.file;

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/AccessDocument",
            data: { 
                pathname: pathname,
                FileBlobName: fileBlobName,
                view_document: true
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200) 
            {
                window.open(data, "_blank", "noopener, noreferrer");
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("info");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Download Document Api: ", error);
            setIsLoading(false);

            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    return (
        <div className = "upload-container my-3 w-100">
            <Grid container spacing = {2} className = "w-100">
                <Grid size = {{ xs: 3 }} className = "pt-0">
                    <div className = "form-group">
                        <div className = "form-control">
                            <input
                                ref = {fileInputRef}
                                className = "form-control"
                                type = "file"
                                name = "documents"
                                multiple = {isMultiple}
                                disabled = {!isWritable}
                                placeholder = "Choose File"
                                accept = {fileTypes.map(type => `.${type}`).join(", ")}
                                onChange = {handleChange}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>

            {(allowFileTypeSelection && (files?.length > 0 || uploadedFiles?.length > 0)) && (
                <>
                    <Grid container className = "file-table-header">
                        <Grid size = {{ xs: 3 }}>
                            Document Name
                        </Grid>
                        <Grid size = {{ xs: 2 }}>
                            Tab
                        </Grid>
                        <Grid size = {{ xs: 4 }}>
                            File Type
                        </Grid>
                        <Grid size = {{ xs: 2 }}>
                            Status
                        </Grid>
                        <Grid size = {{ xs: 1 }}>
                            Actions
                        </Grid>
                    </Grid>

                    {isLoading && <LinearProgress />}
                </>
            )}

            <div className = "file-table-content-container">
                {files?.map((file, index) => (
                    <Grid key = {index} container className = {`${allowFileTypeSelection ? 'file-table-content': 'mt-2'}`}>
                        <Grid size = {{ xs: 3 }}>
                            <a href = "#/" onClick = {(event) => handleViewFile(event, file)}>
                                <span className = "uploaded-file cursor-pointer">
                                    {file.file.name}
                                </span>
                            </a>
                        </Grid>
                        
                        {allowFileTypeSelection && (
                            <>
                                <Grid size = {{ xs: 2 }}>
                                    <span>{file.file.tabType}</span>
                                </Grid>

                                <Grid size = {{ xs: 4 }}>
                                    <span>{file.file.fileType}</span>
                                </Grid>

                                <Grid 
                                    size = {{ xs: 2 }}
                                    sx = {{
                                        backgroundColor: file.file.markedForApproval ? file.file.status ? '#00b242' : '#fe0000' : 'transparent',
                                        color: file.file.markedForApproval ? 'white' : 'black'
                                    }}
                                >
                                    <span>
                                        {file.file.markedForApproval ? (
                                            <>
                                                {file.file.status ? "Approved" : "Not Approved"}
                                            </>
                                        ) : (
                                            'Approval Not Required'
                                        )}
                                    </span>
                                </Grid>
                            </>
                        )}

                        <Grid size = {{ xs: 1 }}>
                            <IconButton 
                                color = "error" 
                                size = "small"
                                disabled = {!isWritable}
                                onClick = {() => handleDeleteExistingFile(index, file.file.id)}
                            >
                                <DeleteIcon fontSize = "small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                {uploadedFiles?.map((file, index) => (
                    <Grid key = {index} container className = {`${allowFileTypeSelection ? 'file-table-content': 'mt-2'}`}>
                        <Grid size = {{ xs: 3 }}>
                            <span className = "uploaded-file cursor-pointer">
                                {file.name}
                            </span>
                        </Grid>

                        {allowFileTypeSelection && (
                            <>
                                <Grid size = {{ xs: 2 }}>
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {false}
                                        isRequired = {true}
                                        isWritable = {true}
                                        filterOn = "Tab"
                                        options = {["Buying Leg", "Selling Leg"]}
                                        selectedOptions = {file.tabType || null}
                                        handleSelectChange = {(filterOn, newValue) => handleTabTypeChange(index, newValue)}
                                    />
                                </Grid>

                                <Grid size = {{ xs: 4 }}>
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        isDisabled = {!file.tabType}
                                        isRequired = {true}
                                        isWritable = {true}
                                        filterOn = "File Type"
                                        options = {file.tabType === "Buying Leg" ? buyingLegFileTypes : file.tabType === "Selling Leg" ? sellingLegFileTypes : []}
                                        selectedOptions = {file.docType || null}
                                        handleSelectChange = {(filterOn, newValue) => handleUploadedFileChange(index, newValue)}
                                    />
                                </Grid>

                                <Grid size = {{ xs: 2 }}>
                                    <FormControlLabel
                                        label = "Mark for Approval"
                                        sx = {{
                                            margin: 'auto',
                                            gap: '0.5em'
                                        }}
                                        control = {
                                            <Checkbox 
                                                size = "small"
                                                sx = {{
                                                    padding: 0,
                                                    marginTop: '-1px'
                                                }}
                                                checked = {file?.markForApproval || false}
                                                onChange = {(event) => handleSetMarkForApproval(index, event.target.checked)}
                                            />
                                        }
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid size = {{ xs: 1 }}>
                            <IconButton 
                                color = "error" 
                                size = "small"
                                disabled = {!isWritable}
                                onClick = {() => handleDeleteNewFile(index)}
                            >
                                <DeleteIcon fontSize = "small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </div>
        </div>
    );
}

export default FileUploader;