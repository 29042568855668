import { useState } from 'react';
import { Navigate, Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Logo from '../../images/logo.png';
import 'semantic-ui-css/semantic.min.css';
import '../../styles/authentication.css';

const ResetPassword = ({ currentUser }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(null);
  const [alertError, setAlertError] = useState(null)
  const { state } = useLocation();

  /* EMAIL VARIABLES */
  const [email, setEmail] = useState(null);

  const handleChange = (event) => 
  {
    if (typeof event.target.value !== "undefined" && event.target.value !== "") 
    {
      const emailPattern = new RegExp(/^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!emailPattern.test(event.target.value)) 
      {
        event.target.setCustomValidity("Please enter a valid email address.");
      }
      else 
      {
        event.target.setCustomValidity("");
      }

      setEmail(event.target.value);
    }
  }

  const handleSubmit = async (event) => 
  {
    event.preventDefault();
  
    setIsLoading(true);
    setAlertSuccess(null);
    setAlertError(null);
    
    await axios({
      method: "post",
      url: "/ForgotPassword",
      data: { email }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200 || status === 202) 
      {
        setAlertSuccess(data?.data);
      } 
      else 
      {
        setAlertError("An error occurred while processing your request. Please try again later or contact the site administrator.");
      }
    })
    .catch((error) => 
    {
      console.log("Forgot Password Api: ", error);
      setIsLoading(false);
      setAlertError(
        error?.response?.status === 429
          ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
          : "An error occurred while processing your request. Please try again later or contact the site administrator."
      );
    });
  }

  return (
    <>
      {currentUser?.email ? (
        <Navigate to = {state?.path || "/"} replace = {true} />
      ) : (
        <div className = "loginContainer">
          <div className = "login">
            <img src = {Logo} alt = "Engro" />
            <h2 className = "mt-0 text-white">Reset Password</h2>

            {alertSuccess ? (
              <Alert variant = "success">{alertSuccess}</Alert>
            ) : alertError && (
              <Alert variant = "danger">{alertError}</Alert>
            )}

            <form onSubmit = {handleSubmit}>
              <div className = "sub-title">
                Enter your email address below to change your password.
              </div>
              
              <div className = "form-group">
                <input 
                  type = "email" 
                  id = "email" 
                  name = "email" 
                  className = "form-control" 
                  placeholder = "Enter Email" 
                  onChange = {handleChange} 
                  required />
              </div>

              <div className = "d-flex justify-content-between align-items-center mt-4">
                <div className = "d-flex justify-content-between align-items-center reset-password m-0">
                  <span></span>
                  <Link to = "/login" className = "login-link">Back To Login</Link>
                </div>

                <Button
                  type = "submit"
                  variant = "primary"
                  disabled = {isLoading}
                  className = "login-button log-in custom_btn"
                >
                  {isLoading ? (
                    <div className = "spinner-border text-info" role = "status" />
                  ) : (
                    'Reset Password'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPassword;